export enum RoleNames {
  Zipliner = 'zipliner',
  Supplier = 'supplier',
}

export interface ObjectWithCommentsAndActivities {
  comments: Comment[];
  activities: Activity[];
}

export interface Part extends ObjectWithCommentsAndActivities {
  vendor_id: string;
  vendor_part_id: string;
  vendor_name: string;
  product_id: string;
  part_number: string;
  part_name: string;
  part_rev: string;
  part_state: string;

  open_tasks: number;
  done_tasks: number;

  thumbnail_image?: ImageDetail;
  main_image?: ImageDetail;

  delivery_package: string;

  // optional, should we remove or default to empty array?
  tasks: Task[];
  comments: Comment[];
  activities: Activity[];
}

export interface ImageDetail {
  src: string;
  width: number;
  height: number;
  alt: string;
}

export interface User {
  user_id: string;
  name: string;
  email: string;
  profile_picture: ImageDetail;
  vendor?: Vendor;
  company_name: string;
  role: string;

  // non-API fields
  isLoggedIn: boolean;
  sessionExpiresAt?: Date;
}

export interface UserAuthResult {
  session: { sid: string; expires_at: Date };
}

export interface Vendor {
  vendor_id: string;
  vendor_name: string;

  contacts: Contact[];
  addresses: Address[];
  express_carriers: Carrier[];
}

export interface Task extends ObjectWithCommentsAndActivities {
  task_id: string;
  title: string;
  description: string;
  start_date: Date;
  end_date: Date;
  status: string;
  progress: number; // 0-100
  documents: Document[];
  subtasks: SubTask[];
  comments: Comment[];
  activities: Activity[];
}

export interface SubTask extends ObjectWithCommentsAndActivities {
  subtask_id: string;
  title: string;
  description: string;
  start_date: Date;
  end_date: Date;
  status: string;
  documents: Document[];
  comments: Comment[];
  activities: Activity[];
}

export interface Document {
  document_id?: string;
  key: string;
  title: string;
  file_type: string; // required for delivery docs
  content_type: string;
  uploaded: Date;
  version: string;
}

export type DocumentType = 'subtask' | 'task' | 'as-built' | 'delivery' | 'purchase-order';

export interface Comment {
  comment_id: string;
  user_id: string;
  user_name: string;
  date: Date;
  comment: string;
}

export interface Activity {
  activity_id: string;
  summary: string;
  activity_type: string;
  author: string;
  topic: string;
  value: string;
  date: Date;
}

export interface PurchaseOrder extends ObjectWithCommentsAndActivities {
  vendor_id: string;
  vendor_name: string;
  purchase_order_id: string;
  po_number: string;
  status: string;
  type: string;
  currency: string;
  total: number;
  order_date: Date;
  revision: string;

  // fields only returned for individual purchase orders
  representative: string;
  inco_terms: string;
  payment_terms: string;
  due_date: Date;

  documents: Document[];
  parts: OrderedPart[];
  comments: Comment[];
  activities: Activity[];
}

// Part ordered in a purchase order
export interface OrderedPart {
  purchase_order_line_id: string;
  part_number: string;
  vendor_part_id: string;
  part_rev: string;
  product_name: string;
  quantity: number;

  // shipment info
  mode_id: string;
  mode: string;
  actual_pickup_location_id?: string;
  pickup_location_id: string;
  pickup_location: string;
  supplier_ready_date: string;
  required_ready_date: string;
  need_by_date: string;
  transit_time: number;
  dropship_address_id: string;
  dropship_address: string;
  status?: string;

  shipment_packages: Package[];

  // schedule info
  msa_line_id: string; // schedule
  schedule_line_id: string; // TODO: is this different than MSA line id?
  has_schedule: boolean;
  schedule_quantity: number;
  actual_quantity: number;

  currency: string;
  unit_price: number;
  total_price: number;
  delivery_package: string;
  urls: string[];
  documents: Document[];
}

// MSA (master scheduling agreement)
export interface Schedule {
  msa_line_id: string;
  purchase_order_line_id: string;
  mode: string;
  mode_id: string;
  pickup_location_id: string;
  pickup_location: string;
  actual_pickup_location_id: string;
  actual_pickup_location: string;
  supplier_ready_date: string;
  required_ready_date: string;
  need_by_date: string;
  schedule_quantity: number;
  actual_quantity: number;
  status: string;
  transit_time: number;
  destination: string;
  dropship_address_id: string;

  part: OrderedPart; // linked by purchase_order_line_id
}

export interface PartDelivery {
  part: OrderedPart;
  quantity: number;
  scheduleId: string;
}

export interface PartDeliveryLineItem {
  id: number;
  partNumber: string;
  quantity: number;
  scheduleId: string;
}

export interface Carrier {
  express_carrier_id: number;
  express_carrier: string;
  express_carrier_threshold: number;
}

export interface Address {
  address_id: string;
  address_street: string;
  address_street2: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
  address_type: string;

  contacts: Contact[];
  addresses: Address[];
}

export interface Contact {
  contact_id: string;
  contact_name: string;
  contact_email: string;
  contact_type: string;

  contacts: Contact[];
  addresses: Address[];
}

export interface Shipment {
  shipment_id: string;

  // contact info
  contact_id: string;
  contact_name: string;

  // location info
  location_id: string;
  location_name: string;

  // shipment info
  ready_by_date: Date;
  tracking_number: string;
  shipment_mode_id: string;
  shipment_mode: string;

  purchase_orders: PurchaseOrder[];
}

export interface ShipmentMode {
  shipment_mode_id: string;
  name: string;
}

export interface LpType {
  lp_type_id: string;
  name: string;
}

export interface UnitOfMeasure {
  unit_id: number;
  systemName: string;
  name: string;
  ratio: number;
}

export interface ShipmentMeasurementSystem {
  system_id: number;
  name: string;
  length: UnitOfMeasure;
  weight: UnitOfMeasure;
  volume: UnitOfMeasure;
}

export interface UnitOfMeasureCategory {
  category_id: number;
  category_name: string;
  units: UnitOfMeasure[];
}

export interface ShipmentLookups {
  shipment_modes: ShipmentMode[];
  lp_types: LpType[];
  vendor_details: Vendor;
  measurement_systems: ShipmentMeasurementSystem[];
}

export interface Package {
  package_id: string;
  package_reference: string;
  package_type_id: string;

  number_of_pcs: number;

  gross_weight: number;
  gross_weight_uom_id: number;

  volume_uom_id: number;
  volume: number;

  dim_uom_id: number;
  dim_length: number;
  dim_width: number;
  dim_height: number;
}

export interface PartsSearchParams {
  vendor: string;
  vendor_name: string;
  supplier_code: string;
  name: string;
  number: string;
  revision: string;
  state: string;
  sre: string;
}

export interface PurchaseOrderSearchParams {
  vendor: string;
  po_number: string;
  vendor_name: string;
  supplier_code: string;
  part_number: string;
  state: string;
}
