import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  apiBodyAsJson,
  apiFetchQueryWithError,
  apiFetchResponseWithError,
} from '../util/api';
import { Document, DocumentType } from '../types';
import { AllParams } from '../util/routes';
import { getQueryKey } from './queryKeys';

interface DocumentCreationResponse {
  file_key: string;
  presigned_upload_url: string;
}

interface DocumentQueryResponse {
  presigned_download_url: string;
}

const createDocumentFromFile = (file: File) => {
  const newDoc: Document = {
    title: file.name,
    content_type: file.type,
    file_type: file.type,
    key: '', // will be set by the server
    uploaded: new Date(),
    version: '1',
  };

  // set the file type based on the file extension
  const extension = file.name.split('.').pop();
  if (extension) {
    newDoc.file_type = extension;
  }

  return newDoc;
};

// queries for downloading and uploading documents and other files
export const useDownloadFile = (params: Partial<AllParams>) => {
  return useMutation(async (key: string) => {
    const signedUrlResponse =
      await apiFetchQueryWithError<DocumentQueryResponse>(
        `vendors/${params.vendorId}/documents/${key}`
      );

    return signedUrlResponse.presigned_download_url;
  });
};

// upload to S3 but don't push the metadata anywhere
export const useUploadWithoutProcessing = (params: Partial<AllParams>) => {
  return useMutation(async (file: File) => {
    const newDocument = createDocumentFromFile(file);

    return uploadAndOptionallyProcessDocument(
      file,
      newDocument,
      getVendorEndpoint(params),
      getVendorEndpoint(params),
      false // don't process the document
    );
  });
};

export const useReplaceFile = (
  params: Partial<AllParams>,
  originalDocument: Document,
  fileType: DocumentType
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (file: File) => {
      // make a doc from that file
      // new document POST passes original doc_id to indicate what it is replacing
      const replacementDocument: Document = createDocumentFromFile(file);
      replacementDocument.document_id = originalDocument.document_id;

      const documentEndpoint = getDocumentEndpoint(params, fileType);

      return uploadAndOptionallyProcessDocument(
        file,
        replacementDocument,
        getVendorEndpoint(params),
        documentEndpoint
      );
    },
    {
      onSuccess: () => {
        // invalidate query that provides the document info
        return queryClient.invalidateQueries(getQueryKey(params));
      },
    }
  );
};

export const useUploadFile = (
  params: Partial<AllParams>,
  fileType: DocumentType
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (file: File) => {
      // make a doc from that file
      const newDocument = createDocumentFromFile(file);

      const documentEndpoint = getDocumentEndpoint(params, fileType);

      return uploadAndOptionallyProcessDocument(
        file,
        newDocument,
        getVendorEndpoint(params),
        documentEndpoint
      );
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(getQueryKey(params));
      },
    }
  );
};

// get endpoint for interacting with current vendor
const getVendorEndpoint = (params: Partial<AllParams>) => {
  return `vendors/${params.vendorId}`;
};

// get endpoint for interacting with task docs
const getTaskDocumentEndpoint = (params: Partial<AllParams>) => {
  return `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}/documents`;
};

// get endpoint for interacting with subtask docs
const getSubtaskDocumentEndpoint = (params: Partial<AllParams>) => {
  return `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}/subtasks/${params.subTaskId}/documents`;
};

// get endpoint for interacting with purchase order docs
const getPurchaseOrderDocumentEndpoint = (params: Partial<AllParams>) => {
  return `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}/documents`;
};

// get endpoint for interacting with as-built docs
const getAsBuiltEndpoint = (params: Partial<AllParams>) => {
  return `vendors/${params.vendorId}/as-built-records`;
};

// get the endpoint to use for metadata by doc type
const getDocumentEndpoint = (
  params: Partial<AllParams>,
  fileType: DocumentType
) => {
  switch (fileType) {
    case 'subtask':
      return getSubtaskDocumentEndpoint(params);
    case 'task':
      return getTaskDocumentEndpoint(params);
    case 'purchase-order':
      return getPurchaseOrderDocumentEndpoint(params);
    case 'as-built':
      return getAsBuiltEndpoint(params);
    default:
      throw new Error(`Unknown file type: ${fileType}`);
  }
};

const uploadAndOptionallyProcessDocument = async (
  file: File,
  document: Document,
  vendorPath: string,
  documentPath: string,
  shouldProcess: boolean = true
) => {
  // get the signed url where we can upload the file
  const signedUrlResponse =
    await apiFetchQueryWithError<DocumentCreationResponse>(
      `${vendorPath}/documents`
    );

  // set the key on the document
  document.key = signedUrlResponse.file_key;

  // upload the file to the signed url
  // use raw fetch so we don't send our auth to aws
  var uploadResponse = await fetch(signedUrlResponse.presigned_upload_url, {
    method: 'PUT',
    headers: {
      'Content-Type': '',
    },
    body: file,
  });

  if (!uploadResponse.ok) {
    throw new Error(`${uploadResponse.status} ${uploadResponse.statusText}`);
  }

  if (shouldProcess) {
    // upload the doc metadata for the proper entity
    await apiFetchResponseWithError(documentPath, {
      method: 'POST',
      body: apiBodyAsJson(document),
    });
  }

  return document;
};
