// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider-border {
  content: '';
  height: 2em;
  border-left: #d74944 3px solid;
}

.divider-empty {
  content: '';
  height: 2em;
  border-left: transparent 3px solid;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/section/Section.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kCAAkC;AACpC","sourcesContent":[".divider-border {\n  content: '';\n  height: 2em;\n  border-left: #d74944 3px solid;\n}\n\n.divider-empty {\n  content: '';\n  height: 2em;\n  border-left: transparent 3px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
