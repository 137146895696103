import React from 'react';

import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { useAuthPasswordReset } from '../../queries/userQueries';
import { useSnackbar } from 'notistack';
import { LoginContainer } from '../../components/login/LoginContainer';
import { LinkRouter } from '../../components/theme/LinkRouter';

// Page for user to reset their password
export const ForgotPassword = () => {
  const [resetSuccess, setResetSuccess] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const resetPasswordMutation = useAuthPasswordReset();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const loginInfo = {
      email: data.get('email'),
    };

    let emailString = (loginInfo.email || '') as string;

    // call API to login
    try {
      await resetPasswordMutation.mutateAsync(emailString);

      setResetSuccess(true);
    } catch {
      enqueueSnackbar(
        `Password reset failed, please check your email address and try again`,
        { variant: 'error' }
      );
    }
  };

  if (resetSuccess) {
    return (
      <LoginContainer>
        <Typography component="h1" variant="h5">
          Password Reset Request Submitted Successfully!
        </Typography>
        <Typography component="h1" variant="body1" mt={2}>
          If you have an account with us, you will receive an email with
          instructions on how to reset your password.
        </Typography>
        <Box mt={4}>
          <LinkRouter to="/login">Return to login</LinkRouter>
        </Box>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer>
      <Typography component="h1" variant="h5">
        Supplier Portal Password Reset
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          helperText="Enter your portal email address"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          {resetPasswordMutation.isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Submit'
          )}
        </Button>
      </Box>
    </LoginContainer>
  );
};
