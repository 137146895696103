import { Box, Typography, Stack, Skeleton } from '@mui/material';
import { SubTask } from '../../../types';
import { sanitizeHTML } from '../../../util/sanitize';
import { Section } from '../../layout/section/Section';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { formatDate } from '../../../util/date';

interface Props {
  isLoading: boolean;
  subTask: SubTask | undefined;
}

export const SubTaskHeader = (props: Props) => {
  const { isLoading, subTask } = props;

  if (isLoading || subTask === undefined) {
    return (
      <Section header={'Sub Task'}>
        <Box>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Box>
        <Stack direction="row" spacing={4} mt={2}>
          <Skeleton variant="text" width={100} height={100} />
          <Skeleton variant="text" width={100} height={100} />
        </Stack>
      </Section>
    );
  }

  return (
    <Section header={subTask.title}>
      <Box>
        <Typography variant="body1">
          {sanitizeHTML(subTask.description)}
        </Typography>
      </Box>
      <Stack direction="row" spacing={4} mt={2}>
        <StatusDisplay status={subTask.status} />
        <Typography>
          START DATE: {formatDate(subTask.start_date)}
        </Typography>
        <Typography>
          END DATE: {formatDate(subTask.end_date)}
        </Typography>
      </Stack>
    </Section>
  );
};
