import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DataGrid } from '@mui/x-data-grid';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--color-table-header)', // TODO: get from theme?
    color: theme.palette.common.black,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .MuiDataGrid-columnHeaders`]: {
    backgroundColor: 'var(--color-table-header)', // TODO: get from theme?
    color: theme.palette.common.black,
    textTransform: 'uppercase',
  },
}));
