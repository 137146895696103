import React from 'react';
import { Activity } from '../../../types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Typography } from '@mui/material';
import { sanitizeHTML } from '../../../util/sanitize';
import { formatDatetime } from '../../../util/date';

interface Props {
  activity: Activity;
}

export const ActivityRow = (props: Props) => (
  <ListItem disableGutters>
    <ListItemText>
      <Stack direction="row" spacing={7}>
        <Typography>{formatDatetime(props.activity.date)}</Typography>
        <Typography>{sanitizeHTML(props.activity.summary)}</Typography>
      </Stack>
    </ListItemText>
  </ListItem>
);
