import { Box, Grid, Skeleton, Stack } from '@mui/material';

export const CommentsLoading = () => (
  <Box
    sx={{
      my: 2,
      mx: 'auto',
      px: 2,
    }}
  >
    <Grid container wrap="nowrap" alignItems="center" spacing={2}>
      <Grid item>
        <Skeleton variant="circular" width={40} height={40} />
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={100} />
        </Stack>
        <Skeleton variant="text" width={300} />
      </Grid>
    </Grid>
  </Box>
);
