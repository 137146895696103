import { useMutation, useQuery } from '@tanstack/react-query';
import { setAuthToken, setUserInfo } from '../auth/AuthStorage';

import { User, UserAuthResult } from '../types';
import {
  apiBodyAsJson,
  apiFetchQueryWithError,
  apiFetchResponseWithError,
  apiResult,
} from '../util/api';
import { apiDatabaseName } from '../util/env';

export const useUserInfoQuery = (vendorId: string, userId: string) =>
  useQuery(['users', 'info', userId], () =>
    apiFetchQueryWithError<User>(`vendors/${vendorId}/users/${userId}`)
  );

// login user and then immediately get user info
export const useAuthLogin = () => {
  return useMutation(async (login: LoginInfo) => {
    const loginResponse = await apiFetchResponseWithError(
      `../web/session/portal_authenticate`,
      {
        method: 'POST',
        body: apiBodyAsJson({
          login: login.email,
          password: login.password,
          db: apiDatabaseName,
        }),
      }
    );

    if (loginResponse.ok) {
      var result = await apiResult<UserAuthResult>(loginResponse);

      // set auth token in local storage for future requests
      setAuthToken(result.session.sid);
    } else {
      throw new Error('Invalid login credentials');
    }

    // we have a token, use it to get user info
    const user = await apiFetchQueryWithError<User>(`userinfo`);

    user.isLoggedIn = true;
    user.sessionExpiresAt = result.session.expires_at;

    setUserInfo(user);

    return user;
  });
};

// request a password reset
export const useAuthPasswordReset = () => {
  return useMutation(async (email: string) => {
    await apiFetchResponseWithError(`password-reset-request`, {
      method: 'POST',
      body: apiBodyAsJson({
        email: email,
      }),
    });
  });
};

// reset password given the request token and a new password
export const useAuthPasswordResetConfirm = () => {
  return useMutation(
    async ({ token, password }: { token: string; password: string }) => {
      await apiFetchResponseWithError(`password-reset`, {
        method: 'POST',
        body: apiBodyAsJson({
          token: token,
          new_password: password,
        }),
      });
    }
  );
};

// request a new account for an existing portal user
export const useAuthNewAccountRequest = () => {
  return useMutation(async (email: string) => {
    await apiFetchResponseWithError(`account-setup-request`, {
      method: 'POST',
      body: apiBodyAsJson({
        email: email,
      }),
    });
  });
};

// confirm a new account request given the request token and a new password
export const useAuthNewAccountRequestConfirm = () => {
  return useMutation(
    async ({ token, password }: { token: string; password: string }) => {
      await apiFetchResponseWithError(`account-setup`, {
        method: 'POST',
        body: apiBodyAsJson({
          token: token,
          new_password: password,
        }),
      });
    }
  );
};

type LoginInfo = {
  email: string;
  password: string;
};
