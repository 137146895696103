import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { TaskList } from '../../components/tasks/list/TaskList';
import { MainPaper } from '../../components/theme/MainPaper';
import { TaskStatus } from '../../components/tasks/status/TaskStatus';
import { ActivityView } from '../../components/activity/view/ActivityView';
import { CommentView } from '../../components/comments/view/CommentView';
import { PartContext } from './PartLayout';
import { isTaskClosed } from '../../helpers/status';

export const PartViewPage = () => {
  const [viewOpenTasks, setViewOpenTasks] = React.useState(true);
  const [viewClosedTasks, setViewClosedTasks] = React.useState(false);

  const navigate = useNavigate();

  const { isLoading, error, part } = useOutletContext<PartContext>();

  const tasks = part?.tasks ?? [];

  const openTasks = tasks.filter(({ status }) => !isTaskClosed(status));
  const closedTasks = tasks.filter(({ status }) => isTaskClosed(status));

  return (
    <MainPaper>
      <Section
        header={
          <Stack direction="row" spacing={4}>
            <Typography variant="h5">Tasks</Typography>
            {part && (
              <TaskStatus
                openCount={openTasks.length}
                doneCount={closedTasks.length}
              />
            )}
          </Stack>
        }
      >
        <Box>
          <Box>
            <Accordion
              expanded={viewOpenTasks}
              onChange={() => setViewOpenTasks((v) => !v)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="open-tasks-content"
                id="open-tasks-header"
              >
                <Typography>
                  Tasks Requiring Action ({openTasks.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TaskList
                  isLoading={isLoading}
                  error={error}
                  tasks={openTasks}
                  taskSelected={(task) => navigate(`${task.task_id}`)}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={2}>
            <Accordion
              expanded={viewClosedTasks}
              onChange={() => setViewClosedTasks((v) => !v)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="closed-tasks-content"
                id="closed-tasks-header"
              >
                <Typography>Closed Tasks ({closedTasks.length})</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TaskList
                  isLoading={isLoading}
                  error={error}
                  tasks={closedTasks}
                  taskSelected={(task) => navigate(`${task.task_id}`)}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Section>
      <Box mt={6}>
        <CommentView comments={part?.comments} />
      </Box>
      <Box mt={6}>
        <ActivityView activities={part?.activities} />
      </Box>
    </MainPaper>
  );
};
