import React from 'react';

import Stack from '@mui/material/Stack';

import { User } from '../../../types';
import { UserAvatar } from '../../users/avatar/UserAvatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/SendSharp';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';

interface Props {
  vendorId: string;
  author: User; // user who will be writing this new comment
  onSubmit: (comment: string) => Promise<void>; // callback to submit the new comment
}

export const CommentEntry = (props: Props) => {
  const [submitting, setSubmitting] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { author } = props;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitting(true);

    const form = event.currentTarget;

    const data = new FormData(form);

    try {
      await props.onSubmit(data.get('comment') as string);

      enqueueSnackbar('Comment posted', { variant: 'success' });

      form.reset();
      setSubmitting(false);
    } catch (error) {
      enqueueSnackbar('Error posting comment', { variant: 'error' });
    }
  };

  return (
    <Box
      sx={{
        mx: 'auto',
        px: 2,
      }}
    >
      <Box component="form" onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <UserAvatar
            vendorId={props.vendorId}
            userId={author.user_id}
          ></UserAvatar>
          <TextField
            multiline
            required
            name="comment"
            label="Comment"
            variant="standard"
            sx={{ width: '25rem' }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={submitting}
            color="success"
            endIcon={<SendIcon />}
          >
            {submitting ? 'Sending...' : 'Send'}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
