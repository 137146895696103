import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  LpType,
  OrderedPart,
  PurchaseOrder,
  PurchaseOrderSearchParams,
  Schedule,
  ShipmentLookups,
  ShipmentMeasurementSystem,
  ShipmentMode,
  Vendor,
} from '../types';
import {
  apiBodyAsJson,
  apiFetchQueryWithError,
  apiFetchWithError,
} from '../util/api';
import { PurchaseOrderParams, VendorParams } from '../util/routes';
import { PurchaseOrderQuoteAction } from '../helpers/status';

export const usePurchaseOrdersQuery = (params: Partial<VendorParams>) =>
  useQuery(['purchase-orders', params.vendorId], () =>
    apiFetchQueryWithError<PurchaseOrder[]>(
      `vendors/${params.vendorId}/purchase-orders`
    )
  );

export const usePurchaseOrderQuery = (params: Partial<PurchaseOrderParams>) =>
  useQuery(
    ['purchase-orders', params.vendorId, params.purchaseOrderId],
    () =>
      apiFetchQueryWithError<PurchaseOrder>(
        `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}`
      ),
    { enabled: !!params.purchaseOrderId }
  );

// Return POs across all vendor, filtered by search params
// query is only enabled if there are filter params
export const useAllPurchaseOrdersQuery = (
  filterParams: PurchaseOrderSearchParams
) =>
  useQuery(
    ['purchaseorders', 'all', filterParams],
    () => {
      var urlParams = new URLSearchParams();

      // go through and add each filter param to the url
      let key: keyof PurchaseOrderSearchParams;

      for (key in filterParams) {
        const value = filterParams[key];
        if (value) {
          urlParams.append(key, value);
        }
      }

      return apiFetchQueryWithError<PurchaseOrder[]>(
        `purchase-orders?${urlParams.toString()}`
      );
    },
    { enabled: Object.values(filterParams).some((v) => !!v) }
  );

export const usePurchaseOrderStatusUpdate = (
  params: Partial<PurchaseOrderParams>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      status,
      reason,
    }: {
      status: PurchaseOrderQuoteAction;
      reason?: string;
    }) =>
      apiFetchWithError<PurchaseOrder>(
        `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}`,
        {
          method: 'PATCH',
          body: apiBodyAsJson({ status, reason }),
        }
      ),
    {
      onSuccess: (order: PurchaseOrder) => {
        queryClient.setQueryData(
          ['purchase-orders', params.vendorId, params.purchaseOrderId],
          order
        );
      },
    }
  );
};

// update unit price of a part via PATCH to covering PO
export const usePurchaseOrderPartUpdate = (
  params: Partial<PurchaseOrderParams>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (part: OrderedPart) =>
      apiFetchWithError<PurchaseOrder>(
        `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}/lines/${part.purchase_order_line_id}`,
        {
          method: 'PATCH',
          body: apiBodyAsJson({ unit_price: part.unit_price }),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'purchase-orders',
          params.vendorId,
          params.purchaseOrderId,
        ]);
      },
    }
  );
};

export const useVendorQuery = (params: Partial<VendorParams>) =>
  useQuery(['vendor', params.vendorId], () =>
    apiFetchQueryWithError<Vendor>(`vendors/${params.vendorId}`)
  );

// to create/modify a delivery, we need to look up:
// - vendor contacts
// - shipment modes
// - lp-types
// - unit of measures (uoms)
export const usePurchaseOrderShipmentLookups = (
  params: Partial<VendorParams>
) =>
  useQuery(['shipment-lookups', params.vendorId], () => {
    return Promise.all([
      apiFetchQueryWithError<Vendor>(`vendors/${params.vendorId}`),
      apiFetchQueryWithError<ShipmentMode[]>(
        `vendors/${params.vendorId}/shipment-mode`
      ),
      apiFetchQueryWithError<LpType[]>(`vendors/${params.vendorId}/lp-types`),
      apiFetchQueryWithError<ShipmentMeasurementSystem[]>(`uoms/shipment`)
    ]).then(
      ([vendor, shipmentModes, lpTypes, measurementSystems]) =>
        ({
          vendor_details: vendor,
          shipment_modes: shipmentModes,
          lp_types: lpTypes,
          measurement_systems: measurementSystems,
        } as ShipmentLookups)
    );
  });

export const useScheduleQuery = (
  params: Partial<PurchaseOrderParams>,
  lineId: string,
  enabled: boolean
) =>
  useQuery(
    ['schedule', params.vendorId, params.purchaseOrderId, lineId],
    () =>
      apiFetchQueryWithError<Schedule[]>(
        `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}/lines/${lineId}/msa`
      ),
    {
      enabled,
    }
  );

export interface ScheduleChanges {
  purchase_order_line_id: string;
  msa_line_id: string;
  actual_quantity?: number;
  supplier_ready_date?: Date;
}

// update schedule for specific msa lines
export const useScheduleUpdate = (params: Partial<PurchaseOrderParams>) => {
  const queryClient = useQueryClient();

  return useMutation(
    (scheduleChanges: ScheduleChanges[]) => {
      // loop through each change and make a PATCH request
      return Promise.all(
        scheduleChanges.map((change) => {
          const body: any = {};

          // only send the fields that have changed
          if (change.actual_quantity) {
            body.actual_quantity = change.actual_quantity;
          }

          if (change.supplier_ready_date) {
            body.supplier_ready_date = change.supplier_ready_date;
          }

          return apiFetchWithError<PurchaseOrder>(
            `vendors/${params.vendorId}/purchase-orders/${params.purchaseOrderId}/lines/${change.purchase_order_line_id}/msa/${change.msa_line_id}`,
            {
              method: 'PATCH',
              body: apiBodyAsJson(body),
            }
          );
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'schedule',
          params.vendorId,
          params.purchaseOrderId,
        ]);
      },
    }
  );
};
