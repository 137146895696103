import { format, isValid, parseISO } from 'date-fns';

const DATE_FORMAT = 'MM/dd/yyyy';
const TIME_FORMAT = 'HH:mm:ss';

// use date-fns to format date and time in our standard format
export const formatDatetime = (dateParam: Date | string) => {
  let date = new Date(dateParam);

  // make sure we have a valid date
  if (isValid(date)) {
    return format(date, `${DATE_FORMAT} ${TIME_FORMAT}`);
  } else {
    return '';
  }
};

// use date-fns to format date in our standard format
export const formatDate = (dateParam: Date | string) => {
  let date = new Date(dateParam);

  // make sure we have a valid date
  if (isValid(date)) {
    return format(date, DATE_FORMAT);
  } else {
    return '';
  }
};

// use date-fns to format a date without time in our standard format
export const formatDateOnly = (dateParam: string) => {
  if (dateParam === '') return '';

  const date = parseISO(dateParam);
  return format(date, DATE_FORMAT);
};

// get date from iso string
export const getDateFromString = (dateParam: string) => {
  return parseISO(dateParam);
}