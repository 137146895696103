import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { ExpandMore } from '@mui/icons-material';
import { ActivityView } from '../../components/activity/view/ActivityView';
import { CommentView } from '../../components/comments/view/CommentView';
import { TaskStatus } from '../../components/tasks/status/TaskStatus';
import { RoleNames } from '../../types';
import { TaskHeader } from '../../components/tasks/header/TaskHeader';
import { SubTaskList } from '../../components/subtasks/list/SubTaskList';
import { RestrictedView } from '../../auth/RestrictedView';
import { ChangeTaskStatus } from '../../components/tasks/changeStatus/ChangeTaskStatus';
import { TaskParams } from '../../util/routes';
import { useTaskQuery } from '../../queries/partQueries';
import { isTaskClosed, taskStatuses } from '../../helpers/status';
import { SetTaskStatus } from '../../components/tasks/setStatus/SetTaskStatus';
import { DocumentUpload } from '../../components/documents/upload/DocumentUpload';
import { DocumentList } from '../../components/documents/list/DocumentList';
import { PortalFunctionality } from '../../util/features';

export const TaskViewPage = () => {
  const [viewOpenTasks, setViewOpenTasks] = React.useState(true);
  const [viewClosedTasks, setViewClosedTasks] = React.useState(false);

  const navigate = useNavigate();

  const params = useParams<TaskParams>();

  const { isLoading, error, data: task } = useTaskQuery(params);

  // example of how to get part if we need it
  // const { part } = useOutletContext<PartContext>();

  const subTasks = task?.subtasks ?? [];

  const openSubTasks = subTasks.filter(({ status }) => !isTaskClosed(status));
  const closedSubTasks = subTasks.filter(({ status }) => isTaskClosed(status));

  return (
    <MainPaper>
      <Grid container mb={4}>
        <Grid item xs={8}>
          <TaskHeader task={task} isLoading={isLoading} />
        </Grid>
        <Grid item xs={4}>
          {task && (
            <>
              {/* Supplier can move new->in progress and in progress -> submitted */}
              {PortalFunctionality.allowSupplierTaskStatusChange &&
                task.status === taskStatuses.New && (
                  <RestrictedView roles={[RoleNames.Supplier]}>
                    <SetTaskStatus
                      status={taskStatuses.InProgress}
                      label="Move To In Progress"
                      loadingLabel="Moving to In Progress..."
                      task={task}
                      onTaskChanged={() => {}}
                    />
                  </RestrictedView>
                )}
              {PortalFunctionality.allowSupplierTaskStatusChange &&
                task.status === taskStatuses.InProgress && (
                  <RestrictedView roles={[RoleNames.Supplier]}>
                    <SetTaskStatus
                      status={taskStatuses.Submitted}
                      label="Submit"
                      loadingLabel="Submitting..."
                      task={task}
                      onTaskChanged={() => {}}
                    />
                  </RestrictedView>
                )}
              <RestrictedView roles={[RoleNames.Zipliner]}>
                {task && (
                  <ChangeTaskStatus task={task} onTaskChanged={() => {}} />
                )}
              </RestrictedView>
            </>
          )}
        </Grid>
      </Grid>
      <Section
        header={
          <Stack direction="row" spacing={4}>
            <Typography variant="h5">Subtasks</Typography>
            {task && (
              <TaskStatus
                openCount={openSubTasks.length}
                doneCount={closedSubTasks.length}
              />
            )}
          </Stack>
        }
      >
        <Box>
          <Box>
            <Accordion
              expanded={viewOpenTasks}
              onChange={() => setViewOpenTasks((v) => !v)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="open-tasks-content"
                id="open-tasks-header"
              >
                <Typography>
                  Tasks Requiring Action ({openSubTasks.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SubTaskList
                  isLoading={isLoading}
                  error={error}
                  subTasks={openSubTasks}
                  subTaskSelected={(subTask) =>
                    navigate(`${subTask.subtask_id}`)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={2}>
            <Accordion
              expanded={viewClosedTasks}
              onChange={() => setViewClosedTasks((v) => !v)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="closed-tasks-content"
                id="closed-tasks-header"
              >
                <Typography>Closed Tasks ({closedSubTasks.length})</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SubTaskList
                  isLoading={isLoading}
                  error={error}
                  subTasks={closedSubTasks}
                  subTaskSelected={(subTask) =>
                    navigate(`${subTask.subtask_id}`)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Section>
      <Box mt={6}>
        <Section header="Documents">
          <Box>
            <DocumentUpload onDocumentUploaded={() => {}} documentType="task" />
            <Box mt={4} maxWidth="75%">
              <DocumentList
                isLoading={isLoading}
                error={error}
                documents={task?.documents}
                documentType="task"
              />
            </Box>
          </Box>
        </Section>
      </Box>
      <Box mt={6}>
        <CommentView comments={task?.comments} />
      </Box>
      <Box mt={6}>
        <ActivityView activities={task?.activities} />
      </Box>
    </MainPaper>
  );
};
