import React from 'react';

import { Navigate } from 'react-router-dom';

import { AuthContext } from './AuthContext';

// Redirect to proper landing page based on user status
// 1. If user is not logged in, redirect to login page
// 2. If user has single vendor, redirect to vendor parts list

export function HomeRedirect() {
  const [authenticatedUser] = React.useContext(AuthContext);

  if (authenticatedUser.isLoggedIn) {
    if (authenticatedUser.vendor?.vendor_id) {
      return (
        <Navigate
          to={`/vendor/${authenticatedUser.vendor.vendor_id}/parts`}
          replace
        />
      );
    } else {
      return <Navigate to={`/vendors`} replace />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
}
