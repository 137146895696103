import React from 'react';

import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import { PurchaseOrder } from '../../../types';
import { formatCurrency } from '../../../util/currency';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { formatDate } from '../../../util/date';
import { NoRowsOverlay } from '../../grid/NoRowsOverlay';

interface Props {
  isLoading: boolean;
  error?: any;
  purchaseOrderSelected: (purchaseOrder: PurchaseOrder) => void;
  purchaseOrders: PurchaseOrder[] | undefined;
}

export const PurchaseOrderList = (props: Props) => {
  const cols: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'po_number',
        headerName: 'PO Number',
        flex: 1.25,
      },
      {
        field: 'currency',
        headerName: 'Currency',
        flex: 0.75,
      },
      {
        type: 'number',
        field: 'total',
        headerName: 'Total',
        headerAlign: 'left',
        align: 'left',
        flex: 0.75,
        valueGetter: (params: GridValueGetterParams<PurchaseOrder, number>) => {
          const row = params.row as PurchaseOrder;
          return formatCurrency(row.currency, row.total);
        },
      },
      {
        type: 'date',
        field: 'order_date',
        headerName: 'Order Date',
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
          return formatDate(params.value);
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams<PurchaseOrder, string>) => {
          return <StatusDisplay status={params.value!}></StatusDisplay>;
        },
      },
    ],
    []
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          autoHeight
          rows={props.purchaseOrders || []}
          columns={cols}
          loading={props.isLoading}
          getRowId={(row) => row.purchase_order_id}
          pageSizeOptions={[100]}
          hideFooterSelectedRowCount={true}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                error={props.error}
                noRowsMessage="No purchase orders found"
              />
            ),
          }}
          onRowClick={(params) => props.purchaseOrderSelected(params.row)}
        />
      </div>
    </div>
  );
};
