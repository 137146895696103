import { NoRows } from './NoRows';

interface NoRowsOverlayProps {
  error?: boolean;
  errorMessage?: string;
  noRowsMessage?: string;
}

export const NoRowsOverlay: React.FC<NoRowsOverlayProps> = (
  props: NoRowsOverlayProps
) => {
  if (props.error) {
    return <NoRows>{props.errorMessage || 'Error loading data'}</NoRows>;
  } else {
    return <NoRows>{props.noRowsMessage}</NoRows>;
  }
};
