import React from 'react';

import {
  Select,
  MenuItem,
  Grid,
  TextField,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from '@mui/material';

import { Shipment, ShipmentLookups } from '../../../types';
import { format, isValid } from 'date-fns';

interface Props {
  lookups: ShipmentLookups;
  shipment: Shipment;
  setShipment: React.Dispatch<React.SetStateAction<Shipment>>;
}

export const DeliveryForm = (props: Props) => {
  const { shipment, setShipment } = props;
  const { contacts, addresses } = props.lookups.vendor_details;

  const handleChange = (
    property: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setShipment((d) => ({ ...d, [property]: event.target.value }));
  };

  const handleContactChange = (event: SelectChangeEvent<string>) => {
    setShipment((d) => ({ ...d, contact_id: event.target.value }));
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setShipment((d) => ({ ...d, location_id: event.target.value }));
  };

  const handleShipModeChange = (event: SelectChangeEvent<string>) => {
    setShipment((d) => ({ ...d, shipment_mode_id: event.target.value }));
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const date = new Date(event.target.value);

    // is this a valid date?
    if (date instanceof Date && !isNaN(date.valueOf())) {
      setShipment((d) => ({ ...d, ready_by_date: date }));
    }
  };

  // local copy of shipment ready that is always a valid date
  let shipmentReadyDate = new Date(shipment.ready_by_date);
  shipmentReadyDate = isValid(shipmentReadyDate)
    ? shipmentReadyDate
    : new Date();

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <FormControl required fullWidth>
          <InputLabel id="select-contacts-label">Delivery Contact</InputLabel>
          <Select
            labelId="select-contacts-label"
            id="select-contacts"
            value={shipment.contact_id || ''}
            label="Choose Delivery Contact"
            onChange={handleContactChange}
          >
            {contacts.map((contact) => (
              <MenuItem key={contact.contact_id} value={contact.contact_id}>
                {contact.contact_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl required fullWidth>
          <InputLabel id="select-contacts-label">Location</InputLabel>
          <Select
            labelId="select-location-label"
            id="select-location"
            value={shipment.location_id || ''}
            label="Choose Location"
            onChange={handleLocationChange}
          >
            {addresses.map((address) => (
              <MenuItem key={address.address_id} value={address.address_id}>
                {address.address_street} {address.address_city}{' '}
                {address.address_state} {address.address_zip}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="date"
          label="Ready By Date"
          required
          defaultValue={format(shipmentReadyDate, 'yyyy-MM-dd')}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl required fullWidth>
          <InputLabel id="select-ship-mode-label">Shipment Mode</InputLabel>
          <Select
            labelId="select-ship-mode-label"
            id="select-ship-mode"
            value={shipment.shipment_mode_id || ''}
            label="Choose Shipment Mode"
            onChange={handleShipModeChange}
          >
            {props.lookups.shipment_modes.map((shipment_modes) => (
              <MenuItem
                key={shipment_modes.shipment_mode_id}
                value={shipment_modes.shipment_mode_id}
              >
                {shipment_modes.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Tracking Number"
          required
          value={shipment.tracking_number}
          onChange={(e) => handleChange('tracking_number', e)}
        />
      </Grid>
    </Grid>
  );
};
