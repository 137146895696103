import React from 'react';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useParams } from 'react-router-dom';

import { usePurchaseOrdersQuery } from '../../../queries/purchaseOrderQueries';

import { Package, PurchaseOrder } from '../../../types';
import { PurchaseOrderParams } from '../../../util/routes';
import { purchaseOrderStatuses } from '../../../helpers/status';
import { DeliveryGrid } from '../grid/DeliveryGrid';
import Colors from '../../theme/Colors';

// style for each purchase order
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  borderColor: Colors.border,
  color: theme.palette.text.secondary,
}));

interface Props {
  purchaseOrders: PurchaseOrder[];
  packages: Package[];
  setPurchaseOrders: (purchaseOrders: PurchaseOrder[]) => void;
}

// holds list of POs for a delivery
export const DeliveryPurchaseOrders = (props: Props) => {
  const params = useParams<PurchaseOrderParams>();

  // get all POs for this vendor
  const { isLoading, data: allPurchaseOrders } = usePurchaseOrdersQuery(params);

  // our add button turns into a dropdown when clicked
  const [isAddingNewPurchaseOrder, setIsAddingNewPurchaseOrder] =
    React.useState(false);

  const addPurchaseOrder = (purchase_order_id: string) => {
    const selectedPurchaseOrder = allPurchaseOrders?.find(
      (p) => p.purchase_order_id === purchase_order_id
    );

    if (selectedPurchaseOrder) {
      console.log('adding PO', selectedPurchaseOrder);
      props.setPurchaseOrders([
        ...props.purchaseOrders,
        { ...selectedPurchaseOrder, parts: [] },
      ]);
    }

    setIsAddingNewPurchaseOrder(false);
  };

  const deletePurchaseOrder = (index: number) => {
    props.setPurchaseOrders([
      ...props.purchaseOrders.slice(0, index),
      ...props.purchaseOrders.slice(index + 1),
    ]);
  };

  // we can only select active POs which are not already added
  const purchaseOrdersAvailableToAdd = allPurchaseOrders?.filter(
    (p) =>
      p.status === purchaseOrderStatuses.PurchaseOrder &&
      !props.purchaseOrders.find(
        (po) => po.purchase_order_id === p.purchase_order_id
      )
  );

  if (isLoading) {
    return (
      <Stack spacing={2}>
        <Item variant="outlined">
          <Box bgcolor={Colors.header}>
            <Typography variant="subtitle1" padding={1}>
              Purchase Orders Loading
            </Typography>
          </Box>
          <Box padding={2}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Item>
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={2}>
        {props.purchaseOrders.map((purchaseOrder, i) => {
          return (
            <Item key={i} variant="outlined">
              <Box
                bgcolor={Colors.header}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="subtitle1" padding={1}>
                  {purchaseOrder.po_number} (Purchase Order)
                </Typography>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => deletePurchaseOrder(i)}
                >
                  Remove
                </Button>
              </Box>
              <Grid container spacing={2} padding={2}>
                <DeliveryGrid
                  purchaseOrder={purchaseOrder}
                  packages={props.packages}
                  setPurchaseOrder={(po) => {
                    props.setPurchaseOrders([
                      ...props.purchaseOrders.slice(0, i),
                      po,
                      ...props.purchaseOrders.slice(i + 1),
                    ]);
                  }}
                />
              </Grid>
            </Item>
          );
        })}
      </Stack>
      <Box pt={2} pb={2}>
        {isAddingNewPurchaseOrder && (
          <Box pt={2} pb={2}>
            <FormControl required fullWidth>
              <InputLabel id="select-po-label">Add Purchase Order</InputLabel>
              <Select
                labelId="select-po-label"
                id="select-po"
                label="Choose Purchase Order"
                value={''}
                onChange={(e) => addPurchaseOrder(e.target.value)}
              >
                {purchaseOrdersAvailableToAdd?.map((po) => (
                  <MenuItem
                    key={po.purchase_order_id}
                    value={po.purchase_order_id}
                  >
                    {po.po_number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={
            isAddingNewPurchaseOrder ||
            purchaseOrdersAvailableToAdd?.length === 0
          }
          onClick={() => setIsAddingNewPurchaseOrder(true)}
        >
          Add Purchase Order
        </Button>
      </Box>
    </>
  );
};
