import React from 'react';

import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../auth/AuthContext';
import { User } from '../../types';
import { requiresLogin } from '../../util/auth';

import './Header.css';

interface Props {
  user: User;
}

export const Header = (props: Props) => {
  const [authenticatedUser] = React.useContext(AuthContext);

  const navigate = useNavigate();

  // don't show the header if the user isn't logged in yet
  if (requiresLogin(authenticatedUser)) {
    return null;
  }

  return (
    <AppBar
      id="zipline-header-wrapper"
      position="relative"
      sx={{ backgroundColor: 'var(--primary-font)' }}
    >
      <Container maxWidth="xl">
        <Toolbar id="zipline-header">
          <button onClick={() => navigate('/')} className="zip-logo">
            <img src="/media/zip-red-z.svg" alt="Zipline Z red logo" />
            <Typography variant="h6" color="inherit" noWrap>
              {' '}
              Supplier Portal
            </Typography>
          </button>

          <span className="header-spacer">|</span>
          <Typography variant="h6" color="inherit" noWrap>
            {props.user.company_name}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
