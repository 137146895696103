import React from 'react';

import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { Document, DocumentType } from '../../../types';
import {
  useUploadFile,
  useUploadWithoutProcessing,
} from '../../../queries/fileQueries';
import { AllParams } from '../../../util/routes';

interface Props {
  documentType: DocumentType;
  onDocumentUploaded: (document: Document) => void;
}

export const DocumentUpload = (props: Props) => {
  const params = useParams<AllParams>();
  const uploadMutation = useUploadFile(params, props.documentType);

  const uploadWithoutProcessingMutation = useUploadWithoutProcessing(params);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // delivery docs are uploaded without processing
      if (props.documentType === 'delivery') {
        uploadWithoutProcessingMutation.mutate(file, {
          onSuccess: (document) => {
            props.onDocumentUploaded(document);
          },
        });
      } else {
        // all other docs are uploaded & metadata processed
        uploadMutation.mutate(file, {
          onSuccess: (document) => {
            props.onDocumentUploaded(document);
          },
        });
      }
    }

    // set file upload value back to empty so any selection will retrigger
    event.target.value = '';
  };

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Button
        variant="contained"
        component="label"
        disabled={uploadMutation.isLoading}
      >
        Upload New File
        <input type="file" onChange={handleChange} hidden />
      </Button>
      {uploadMutation.isLoading && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              Uploading {uploadMutation.variables?.name}
            </Typography>
          </Box>
          <Box sx={{ width: '75%', ml: 1 }}>
            <LinearProgress variant="indeterminate" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
