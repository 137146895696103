import { Box, Button, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DeliveryForm } from '../../components/deliveries/form/DeliveryForm';
import { DeliveryPurchaseOrders } from '../../components/deliveries/purchaseOrder/DeliveryPurchaseOrders';
import { Section } from '../../components/layout/section/Section';

import { MainPaper } from '../../components/theme/MainPaper';
import {
  usePurchaseOrderQuery,
  usePurchaseOrderShipmentLookups,
} from '../../queries/purchaseOrderQueries';
import {
  useShipmentCreateOrUpdate,
  useShipmentQuery,
} from '../../queries/shipmentQueries';
import { Package, Shipment } from '../../types';
import { DeliveryParams } from '../../util/routes';
import { DeliveryPackages } from '../../components/deliveries/packages/DeliveryPackages';
import { validateShipment } from '../../util/validation';
import { usePickupRequestCreate } from '../../queries/pickupRequestQueries';
import {
  ShipmentMeasurementSystemCategories,
  getUnitsOfMeasureByShipmentSystems,
} from '../../util/uom';

const getDefaultShipment = (): Partial<Shipment> => ({
  shipment_id: '',
  ready_by_date: new Date(),
  tracking_number: '',
  purchase_orders: [],
});

export const DeliveryModifyPage = () => {
  const params = useParams<DeliveryParams>();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // get optional purchase order to add to this shipment
  const [searchParams] = useSearchParams();
  const purchaseOrderId = searchParams.get('purchaseOrderId');

  const [shipment, setShipment] = React.useState<Shipment>(
    getDefaultShipment() as Shipment
  );

  const [shipmentPackages, setShipmentPackages] = React.useState<Package[]>([]);

  const { isLoading: isShipmentLoading, data: shipmentData } =
    useShipmentQuery(params);

  // load up purchase order to add to shipment, if provided
  const { isLoading: isPurchaseOrderLoading, data: purchaseOrder } =
    usePurchaseOrderQuery({
      vendorId: params.vendorId,
      purchaseOrderId: purchaseOrderId || undefined,
    });

  // load up lookup info like contacts & addresses
  const { isLoading: areLookupsLoading, data: lookups } =
    usePurchaseOrderShipmentLookups(params);

  // will allow us to create or update shipments
  const shipmentMutation = useShipmentCreateOrUpdate(params);

  // create pickup-request mutation if we can't express ship
  const pickupRequestMutation = usePickupRequestCreate(params);

  // if we have a shipment id, load up the shipment
  React.useEffect(() => {
    if (shipmentData) {
      setShipment({ ...shipmentData });
    }
  }, [shipmentData]);

  // add the purchase order to the shipment if it is not already there
  React.useEffect(() => {
    if (
      purchaseOrder &&
      !shipment.purchase_orders.some(
        (po) => po.purchase_order_id === purchaseOrder.purchase_order_id
      )
    ) {
      // we don't want the associated parts/comments/activities with our PO model for shipments
      const sanitizedPurchaseOrder = {
        ...purchaseOrder,
        parts: [],
        comments: [],
        activities: [],
      };

      setShipment({
        ...shipment,
        purchase_orders: [...shipment.purchase_orders, sanitizedPurchaseOrder],
      });
    }
  }, [purchaseOrder, shipment]);

  const submitPickupRequest = () => {
    // TODO: validate pickup request

    pickupRequestMutation.mutate(
      { shipment },
      {
        onSuccess: (pr) => {
          enqueueSnackbar('Pickup request submitted successfully', {
            variant: 'success',
          });
          navigate(`/vendor/${params.vendorId}/pickup-requests/`);
        },
        onError: () => {
          enqueueSnackbar(
            'There was a problem submitting this pickup request.  Please try again.',
            {
              variant: 'error',
            }
          );
        },
      }
    );
  };

  const submitShipment = () => {
    const shipmentValidationErrors = validateShipment(shipment);

    // TODO: improve validation, for now just show first error
    if (shipmentValidationErrors.length > 0) {
      enqueueSnackbar(shipmentValidationErrors[0], {
        variant: 'error',
      });
      return;
    }

    // TODO: determine if we should create a shipment or pickup request.
    // For now, we just create everything as a shipment

    shipmentMutation.mutate(
      { shipment },
      {
        onSuccess: (ship) => {
          // updating shipment returns null, so we need to use the id from the params
          const savedShipId = params.shipmentId || ship.shipment_id;

          enqueueSnackbar('Shipment saved successfully', {
            variant: 'success',
          });
          navigate(`/vendor/${params.vendorId}/deliveries/${savedShipId}`);
        },
        onError: () => {
          enqueueSnackbar(
            'There was a problem saving this shipment.  Please try again.',
            {
              variant: 'error',
            }
          );
        },
      }
    );
  };

  // expressShip if every package is individually under the `express_carrier_threshold` weight
  const canExpressShip = React.useMemo(() => {
    if (lookups === undefined) {
      return false;
    }

    const express_carrier_threshold =
      lookups?.vendor_details.express_carriers[0].express_carrier_threshold ||
      -1;

    const weightUnits = getUnitsOfMeasureByShipmentSystems(
      lookups.measurement_systems,
      ShipmentMeasurementSystemCategories.Weight
    );

    return shipmentPackages.every((pack) => {
      const weight = pack.gross_weight;
      const uom = weightUnits.find(
        (u) => u.unit_id === pack.gross_weight_uom_id
      );

      if (uom === undefined) {
        return false;
      }

      const standardWeight = uom?.ratio * weight;

      return standardWeight <= express_carrier_threshold;
    });
  }, [shipmentPackages, lookups]);

  const hasExistingShipment = shipmentData !== undefined;

  const waitingForShipment = hasExistingShipment && isShipmentLoading;

  const waitingForPurchaseOrder =
    isPurchaseOrderLoading && purchaseOrder !== undefined;

  const waitingForLookups = areLookupsLoading || !lookups;

  if (waitingForShipment || waitingForLookups || waitingForPurchaseOrder) {
    return (
      <MainPaper>
        <Section header={'Delivery'}>
          <Stack spacing={2}>
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={60} />
          </Stack>
        </Section>
      </MainPaper>
    );
  }

  const headerContent = hasExistingShipment
    ? 'Edit Delivery'
    : 'Create New Delivery';

  return (
    <MainPaper>
      <Section header={headerContent}>
        <Box width="100%">
          <Box mb={4} mt={4}>
            <DeliveryForm
              lookups={lookups}
              shipment={shipment}
              setShipment={setShipment}
            ></DeliveryForm>
          </Box>
          <Box padding={2}>Shipping files here</Box>

          <Box>
            <DeliveryPackages
              lookups={lookups}
              packages={shipmentPackages}
              setPackages={(packages) => {
                setShipmentPackages(packages);
              }}
            />
          </Box>
          <Box>
            <DeliveryPurchaseOrders
              purchaseOrders={shipment.purchase_orders}
              packages={shipmentPackages}
              setPurchaseOrders={(purchaseOrders) =>
                setShipment({ ...shipment, purchase_orders: purchaseOrders })
              }
            />
          </Box>
          <Box mt={4}>
            {canExpressShip ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={shipmentMutation.isLoading}
                onClick={submitShipment}
              >
                {shipmentMutation.isLoading
                  ? 'Submitting...'
                  : hasExistingShipment
                  ? 'Update Delivery'
                  : 'Create Delivery'}
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={pickupRequestMutation.isLoading}
                onClick={submitPickupRequest}
              >
                {pickupRequestMutation.isLoading
                  ? 'Submitting...'
                  : 'Create Pickup Request'}
              </Button>
            )}
          </Box>
        </Box>
      </Section>
      <pre>
        <code>{JSON.stringify(shipmentPackages, null, 2)}</code>
        <code>{JSON.stringify(shipment, null, 2)}</code>
      </pre>
    </MainPaper>
  );
};
