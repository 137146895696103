import { Shipment } from '../types';

// TODO: use yup or zod or something for more robust validation
export const validateShipment = (shipment: Shipment) => {
  const errors: string[] = [];

  // for now, let's just validate the basics to make sure we have POs, parts and packages
  if (!shipment.purchase_orders || shipment.purchase_orders.length === 0) {
    errors.push('Shipment must have at least one purchase order');
  }

  // make sure each PO has at least one part
  if (!shipment.purchase_orders.some((po) => po.parts && po.parts.length > 0)) {
    errors.push('Every purchase order must have at least one part');
  }

  // make sure every part has at least one package
  if (
    !shipment.purchase_orders.some(
      (po) =>
        po.parts?.some(
          (part) => part.shipment_packages && part.shipment_packages.length > 0
        )
    )
  ) {
    errors.push('Every part must have at least one package');
  }

  // TODO: decide if we should auto remove invalid POs or lines

  return errors;
};
