import React from 'react';

import { useParams } from 'react-router-dom';

import { SubTask } from '../../../types';
import { ChangeStatus } from '../../parts/changeStatus/ChangeStatus';
import { SubtaskParams } from '../../../util/routes';
import { useSubTaskStatusUpdate } from '../../../queries/partQueries';
import { taskStatuses } from '../../../helpers/status';

interface Props {
  subTask: SubTask;
  onSubTaskChanged: (subTask: SubTask) => void;
}

const statuses = Object.values(taskStatuses);

export const ChangeSubTaskStatus = (props: Props) => {
  const params = useParams<SubtaskParams>();

  const updateSubTaskStatus = useSubTaskStatusUpdate(params);

  const changeStatus = async (status: string) => {
    await updateSubTaskStatus.mutateAsync(status);
  };

  return (
    <ChangeStatus
      statuses={statuses}
      currentStatus={props.subTask.status}
      changeStatus={changeStatus}
    />
  );
};
