import { apiBaseUrl } from '../util/env';
import { getAuthToken } from '../auth/AuthStorage';

const getApiUrl = (path: string) => new URL(path, apiBaseUrl).toString();

// TODO: remove export once document upload reworked
// TODO: make it throw an exception on non-ok and rework overloads
// base configurable, authenticated fetch
export const apiFetch = async (
  url: string,
  init?: RequestInit,
  additionalHeaders?: HeadersInit
): Promise<Response> => {
  const authToken = getAuthToken();

  return fetch(getApiUrl(url), {
    ...init,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Openerp-Session-Id': authToken || '',
      ...additionalHeaders,
    },
  });
};

// fetch and unwrap json from portal api
export const apiFetchWithError = async <T>(
  url: string,
  init?: RequestInit,
  additionalHeaders?: HeadersInit
): Promise<T> => {
  const res = await apiFetch(url, init, additionalHeaders);

  if (!res.ok) {
    // res not ok but probably still json, so try to parse it
    let json: any = {};
    try {
      json = await res.json();
    } catch {
      // not json, so throw the status text
      throw new Error(`${res.status} ${res.statusText}`);
    }

    // if it has an error field, throw that
    if (json.error) {
      throw new Error(json.error.code + ' ' + json.error.message);
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  }

  return (await res.json()).result;
};

// helper for apiFetchWithError for requests with no data (e.g. GET style requests)
// API uses JSONRPC which treats GET requests as POST requests with empty body
export const apiFetchQueryWithError = async <T>(
  url: string,
  init?: RequestInit,
  additionalHeaders?: HeadersInit
): Promise<T> => {
  return apiFetchWithError(
    url,
    { ...init, method: 'POST', body: '{}' },
    additionalHeaders
  );
};

// TODO: find a way to merge these two apiFetchWithError functions
// this fetch returns no data but errors on not ok
export const apiFetchEmptyWithError = async (
  url: string,
  init?: RequestInit,
  additionalHeaders?: HeadersInit
): Promise<void> => {
  const res = await apiFetch(url, init, additionalHeaders);

  if (!res.ok) {
    throw new Error(`${res.status} ${res.statusText}`);
  }
};

// TODO: replace empty fetch with this one
// and perhaps use `apiFetchWithError` as a wrapper for this one
export const apiFetchResponseWithError = async (
  url: string,
  init?: RequestInit,
  additionalHeaders?: HeadersInit
): Promise<Response> => {
  const res = await apiFetch(url, init, additionalHeaders);

  if (!res.ok) {
    throw new Error(`${res.status} ${res.statusText}`);
  }

  return res;
};

// API uses json-rpc 2.0, so we need to wrap the body with some metadata and return as a string
export const apiBodyAsJson = (body: any): string =>
  JSON.stringify({
    jsonrpc: '2.0',
    params: {
      ...body,
    },
  });

export const unwrapApiResponseBody = <T>(body: any): T | undefined =>
  body?.result;

// result is in "result" field so unwrap it
export const apiResult = async <T>(response: Response): Promise<T> =>
  (await response.json()).result;
