import { LinkProps, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

// MUI Link component that uses react-router-dom's Link component
export const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);
