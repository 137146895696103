import React from 'react';

import { Avatar, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { User } from '../../types';

import './NavUser.css';
import { AuthContext } from '../../auth/AuthContext';
import { removeAuthToken, removeUserInfo } from '../../auth/AuthStorage';

export const NavUser = () => {
  const [authenticatedUser, setAuthenticatedUser] =
    React.useContext(AuthContext);

  const navigate = useNavigate();

  const logout = () => {
    // for now there is only the logout action, so let's cheat and just log user out
    removeUserInfo();
    removeAuthToken();
    setAuthenticatedUser({ isLoggedIn: false } as User);
    navigate('/login');
  };

  if (!authenticatedUser.isLoggedIn) {
    return null;
  }

  return (
    <Stack
      id="nav-user"
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ flexGrow: 0 }}
    >
      <Avatar
        alt={authenticatedUser.name}
        src={authenticatedUser.profile_picture.src}
      />
      <Stack id="nav-user-details">
        <Typography variant="subtitle1" title={authenticatedUser.role}>
          {authenticatedUser.name}
        </Typography>
        <Link
          className="signout-link"
          href="#"
          onClick={logout}
          underline="always"
        >
          Sign Out
        </Link>
      </Stack>
    </Stack>
  );
};
