import React from 'react';

import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom';

import { AuthContext } from '../../auth/AuthContext';

import './NavBar.css';
import { NavUser } from './NavUser';
import { requiresLogin } from '../../util/auth';
import { PortalFunctionality } from '../../util/features';

const pages = ['Parts', 'Purchase Orders'];

if (PortalFunctionality.showDeliveries) {
  pages.push('Deliveries');
}

export const NavBar = () => {
  const [auth] = React.useContext(AuthContext);

  // should always have vendorId and it needs to be prepended to each link
  const location = useLocation();
  const match = matchPath('/vendor/:vendorId/*', location.pathname);

  const hasVendor = match?.params.vendorId !== undefined;

  const pathStart = hasVendor ? `/vendor/${match?.params.vendorId}` : '/vendors';

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  // hide the nav if the user needs to login
  if (requiresLogin(auth)) {
    return null;
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar id="navbar" position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pathStart && pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link
                    to={`${pathStart}/${page.toLowerCase().replace(' ', '')}`}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            ZIPLINE
          </Typography>
          <Box
            id="navbar-main-links"
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            {pathStart && pages.map((page) => (
              <NavLink
                key={page}
                to={`${pathStart}/${page.toLowerCase().replace(' ', '')}`}
              >
                <Typography textAlign="center">{page}</Typography>
              </NavLink>
            ))}
          </Box>
          <NavUser />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
