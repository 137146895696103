import { useEffect } from 'react';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { Package, ShipmentLookups } from '../../../types';
import Colors from '../../theme/Colors';
import {
  getUnitsOfMeasureByShipmentSystems,
  ShipmentMeasurementSystemCategories,
} from '../../../util/uom';

// style for each package item
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  borderColor: Colors.border,
  color: theme.palette.text.secondary,
}));

interface Props {
  lookups: ShipmentLookups;
  packages: Package[];
  setPackages: (packages: Package[]) => void;
}

const blankPackage: Package = {
  package_id: '',
  package_type_id: '',
  package_reference: '',

  number_of_pcs: 1,

  gross_weight: 0,
  gross_weight_uom_id: 0,

  volume: 0,
  volume_uom_id: 0,

  dim_uom_id: 0,
  dim_length: 0,
  dim_width: 0,
  dim_height: 0,
};

let packageIdentity = 1;

const getBlankPackage = (
  defaultWeightUomId: number,
  defaultDimUomId: number
) => {
  const blank: Package = {
    ...blankPackage,
    package_id: 'NEWID_' + packageIdentity,
    package_reference: 'Package ' + packageIdentity,
    gross_weight_uom_id: defaultWeightUomId,
    dim_uom_id: defaultDimUomId,
  };

  packageIdentity++;

  return blank;
};

export const DeliveryPackages = (props: Props) => {
  const weightUnits = getUnitsOfMeasureByShipmentSystems(
    props.lookups.measurement_systems,
    ShipmentMeasurementSystemCategories.Weight
  );
  const dimensionsUnits = getUnitsOfMeasureByShipmentSystems(
    props.lookups.measurement_systems,
    ShipmentMeasurementSystemCategories.Length
  );

  // add an initial package if we don't have any
  useEffect(() => {
    if (props.packages.length === 0) {
      // start numbering at package #1
      packageIdentity = 1;

      props.setPackages([
        getBlankPackage(weightUnits[0].unit_id, dimensionsUnits[0].unit_id),
      ]);
    }
  }, [dimensionsUnits, props, weightUnits]);

  const addPackage = () => {
    props.setPackages([
      ...props.packages,
      getBlankPackage(weightUnits[0].unit_id, dimensionsUnits[0].unit_id),
    ]);
  };

  const deletePackage = (index: number) => {
    const newPackages = [...props.packages];
    newPackages.splice(index, 1);
    props.setPackages(newPackages);
  };

  const handleChange = (index: number, field: string, value: any) => {
    const newPackages = [...props.packages];
    newPackages[index] = { ...newPackages[index], [field]: value };

    // calculate volume
    const systemOfMeasure = dimensionsUnits.find(
      (u) => u.unit_id === newPackages[index].dim_uom_id
    )?.systemName;

    if (systemOfMeasure) {
      newPackages[index].volume =
        newPackages[index].dim_length *
        newPackages[index].dim_width *
        newPackages[index].dim_height;
      newPackages[index].volume_uom_id =
        props.lookups.measurement_systems.find(
          (s) => s.name === systemOfMeasure
        )?.volume.unit_id || 0;
    }

    props.setPackages(newPackages);
  };

  return (
    <>
      <Stack spacing={2}>
        {props.packages.map((pkg, i) => {
          return (
            <Item key={i} variant="outlined">
              <Box
                bgcolor={Colors.header}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="subtitle1" padding={1}>
                  <strong>{pkg.package_reference}</strong> information
                </Typography>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => deletePackage(i)}
                >
                  Remove
                </Button>
              </Box>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="Piece Count"
                    type="number"
                    required
                    value={pkg.number_of_pcs}
                    onChange={(e) =>
                      handleChange(i, 'number_of_pcs', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl required fullWidth>
                    <InputLabel id="select-lp-label">LP Type</InputLabel>
                    <Select
                      labelId="select-lp-label"
                      id="select-lp"
                      value={pkg.package_type_id || ''}
                      label="Choose LP Type"
                      onChange={(e) =>
                        handleChange(i, 'package_type_id', e.target.value)
                      }
                    >
                      {props.lookups.lp_types.map((lp_type) => (
                        <MenuItem
                          key={lp_type.lp_type_id}
                          value={lp_type.lp_type_id}
                        >
                          {lp_type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Package Reference"
                    type="text"
                    required
                    value={pkg.package_reference || ''}
                    onChange={(e) =>
                      handleChange(i, 'package_reference', e.target.value)
                    }
                  />
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="select-weight-label">
                        Weight Units
                      </InputLabel>
                      <Select
                        labelId="select-weight-label"
                        id="select-weight"
                        value={pkg.gross_weight_uom_id || ''}
                        label="Choose Weight Unit"
                        onChange={(e) =>
                          handleChange(i, 'gross_weight_uom_id', e.target.value)
                        }
                      >
                        {weightUnits.map((unit) => (
                          <MenuItem key={unit.unit_id} value={unit.unit_id}>
                            {unit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Weight"
                      type="number"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              weightUnits.find(
                                (u) => u.unit_id === pkg.gross_weight_uom_id
                              )?.name
                            }
                          </InputAdornment>
                        ),
                      }}
                      value={pkg.gross_weight || ''}
                      onChange={(e) =>
                        handleChange(i, 'gross_weight', e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="select-dimensions-label">
                        Dimensions Units
                      </InputLabel>
                      <Select
                        labelId="select-dimensions-label"
                        id="select-dimensions"
                        value={pkg.dim_uom_id || ''}
                        label="Choose Dimensions Unit"
                        onChange={(e) =>
                          handleChange(i, 'dim_uom_id', e.target.value)
                        }
                      >
                        {dimensionsUnits.map((unit) => (
                          <MenuItem key={unit.unit_id} value={unit.unit_id}>
                            {unit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Length"
                      type="number"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              dimensionsUnits.find(
                                (u) => u.unit_id === pkg.dim_uom_id
                              )?.name
                            }
                          </InputAdornment>
                        ),
                      }}
                      value={pkg.dim_length || ''}
                      onChange={(e) =>
                        handleChange(i, 'dim_length', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Width"
                      type="number"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              dimensionsUnits.find(
                                (u) => u.unit_id === pkg.dim_uom_id
                              )?.name
                            }
                          </InputAdornment>
                        ),
                      }}
                      value={pkg.dim_width || ''}
                      onChange={(e) =>
                        handleChange(i, 'dim_width', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Height"
                      type="number"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              dimensionsUnits.find(
                                (u) => u.unit_id === pkg.dim_uom_id
                              )?.name
                            }
                          </InputAdornment>
                        ),
                      }}
                      value={pkg.dim_height || ''}
                      onChange={(e) =>
                        handleChange(i, 'dim_height', e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Item>
          );
        })}
      </Stack>
      <Box pt={2} pb={2}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={addPackage}>
          Add Package Information
        </Button>
      </Box>
    </>
  );
};
