import React from 'react';

import { useParams } from 'react-router-dom';

import { Task } from '../../../types';
import { ChangeStatus } from '../../parts/changeStatus/ChangeStatus';
import { TaskParams } from '../../../util/routes';
import { useTaskStatusUpdate } from '../../../queries/partQueries';
import { taskStatuses } from '../../../helpers/status';

interface Props {
  task: Task;
  onTaskChanged: (task: Task) => void;
}

const statuses = Object.values(taskStatuses);

export const ChangeTaskStatus = (props: Props) => {
  const params = useParams<TaskParams>();

  const updateTaskStatus = useTaskStatusUpdate(params);

  const changeStatus = async (status: string) => {
    await updateTaskStatus.mutateAsync(status);
  };

  return (
    <ChangeStatus
      statuses={statuses}
      currentStatus={props.task.status}
      changeStatus={changeStatus}
    />
  );
};
