import { Grid, styled, Typography } from '@mui/material';

interface Props {
  property: string;
  valueComponent?: React.ReactNode;
  value?: string;
}

export const ItemLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textTransform: 'uppercase',
}));

export const ItemContent = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

// display property -> value pair in a grid
export const PropertyDisplay = (props: Props) => {
  return (
    <Grid container alignItems='center'>
      <Grid item xs={6}>
        <ItemLabel>{props.property}</ItemLabel>
      </Grid>
      <Grid item xs>
        {props.valueComponent ? (
          props.valueComponent
        ) : (
          <ItemContent>{props.value}</ItemContent>
        )}
      </Grid>
    </Grid>
  );
};
