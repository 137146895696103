import React from 'react';

import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';

import { ConfirmationDialog } from '../../confirmation/ConfirmationDialog';
import { PurchaseOrder } from '../../../types';
import { usePurchaseOrderStatusUpdate } from '../../../queries/purchaseOrderQueries';

interface Props {
  vendorId: string;
  order: PurchaseOrder;
}

export const ConfirmQuoteButton = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const purchaseOrderUpdate = usePurchaseOrderStatusUpdate({
    vendorId: props.vendorId,
    purchaseOrderId: props.order.purchase_order_id,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onConfirm = async (confirmed: boolean) => {
    if (confirmed) {
      setProcessing(true);

      purchaseOrderUpdate.mutate(
        { status: 'Confirmed' },
        {
          onSuccess: (order) => {
            enqueueSnackbar('Purchase Order RFQ Submitted', {
              variant: 'info',
            });
          },
          onSettled: () => {
            setOpen(false);
            setProcessing(false);
          },
          onError: () => {
            enqueueSnackbar('Error submitting purchase order RFQ', {
              variant: 'error',
            });
          },
        }
      );
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={() => setOpen(true)}>
        Submit RFQ
      </Button>
      <ConfirmationDialog
        id="confirm-quote-dialog"
        keepMounted
        open={open}
        processing={processing}
        title="Confirm Quote"
        content={`Are you sure you want to submit this RFQ for ${props.order.purchase_order_id}?`}
        requireReason={false}
        severity="success"
        onClose={onConfirm}
      ></ConfirmationDialog>
    </>
  );
};
