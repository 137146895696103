import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { PurchaseOrderSearchParams, RoleNames } from '../../types';
import { useDebounce } from '../../util/debounce';
import { PurchaseOrderList } from '../../components/purchaseOrders/list/PurchaseOrderList';
import { useAllPurchaseOrdersQuery } from '../../queries/purchaseOrderQueries';
import { AuthContext } from '../../auth/AuthContext';

// Allows searching through all vendor parts as a zipliner
export const ZiplinerPurchaseOrderPickerPage = () => {
  const [authenticatedUser] = React.useContext(AuthContext);
  
  const navigate = useNavigate();

  const [search, setSearch] = React.useState<PurchaseOrderSearchParams>({
    vendor: '',
    vendor_name: '',
    part_number: '',
    po_number: '',
    supplier_code: '',
    state: '',
  });

  const debouncedSearch = useDebounce(search, 500);

  const query = useAllPurchaseOrdersQuery(debouncedSearch);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };

  if (authenticatedUser.role !== RoleNames.Zipliner) {
    // send user to home page if not a zipliner
    return (
      <Navigate
        to={`/vendor/${authenticatedUser.vendor?.vendor_id}/purchaseorders`}
        replace
      />
    );
  }

  return (
    <MainPaper>
      <Section header={'Purchase Order List'}>
        <Paper elevation={3} sx={{ mb: 1 }}>
          <Box padding={3}>
            <Typography variant="h6">Search</Typography>
            <Typography variant="body2" color="textSecondary" mb={1}>
              Enter at least one search term to filter all purchase orders.
              Entering multiple terms will result in an AND search.
            </Typography>
            <Grid container spacing={2} maxWidth="40%">
              <Grid item xs={6} md={6}>
                <TextField
                  label="Vendor Name"
                  name="vendor_name"
                  fullWidth
                  value={search.vendor_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Supplier Code"
                  name="supplier_code"
                  fullWidth
                  value={search.supplier_code}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label="PO Number"
                  name="po_number"
                  fullWidth
                  value={search.po_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Zipline Part Number"
                  name="part_number"
                  fullWidth
                  value={search.part_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Status"
                  name="state"
                  fullWidth
                  value={search.state}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <PurchaseOrderList
          isLoading={query.isLoading && query.isFetching}
          error={query.error}
          purchaseOrders={query.data || []}
          purchaseOrderSelected={(po) =>
            navigate(`/vendor/${po.vendor_id}/purchaseorders/${po.purchase_order_id}`)
          }
        />
      </Section>
    </MainPaper>
  );
};
