import React from 'react';

import { Grid } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

import { PartsSidebar } from '../../components/parts/sidebar/PartsSidebar';
import { Part } from '../../types';
import { PartParams } from '../../util/routes';
import { usePartQuery } from '../../queries/partQueries';

export interface PartContext {
  isLoading: boolean;
  error?: Error;
  part?: Part;
  setPart: React.Dispatch<React.SetStateAction<Part | undefined>>;
}

export const PartLayout = () => {
  const params = useParams<PartParams>();

  const {
    isLoading,
    error,
    data: part,
  } = usePartQuery(params);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <PartsSidebar isLoading={isLoading} part={part} />
      </Grid>
      <Grid item xs={12} md={10}>
        <Outlet context={{ isLoading, error, part }} />
      </Grid>
    </Grid>
  );
};
