import { useMemo } from 'react';

import { Shipment } from '../../../types';

import { GridColDef } from '@mui/x-data-grid';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { NoRowsOverlay } from '../../grid/NoRowsOverlay';

interface Props {
  isLoading: boolean;
  error?: any;
  shipmentSelected: (shipment: Shipment) => void;
  shipments: Shipment[] | undefined;
}

export const ShipmentList = (props: Props) => {
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'shipment_id',
        headerName: 'Id',
        flex: 1,
      },
      {
        field: 'contact_name',
        headerName: 'Contact',
        flex: 1,
      },
      {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        flex: 1,
      },
      {
        field: 'location_name',
        headerName: 'Destination',
        flex: 1,
      },
      {
        field: 'ready_by_date',
        headerName: 'Date',
        flex: 1,
      },
    ],
    []
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          autoHeight
          rows={props.shipments || []}
          columns={cols}
          getRowId={(row) => row.shipment_id}
          loading={props.isLoading}
          pageSizeOptions={[100]}
          hideFooterSelectedRowCount={true}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                error={props.error}
                noRowsMessage="No shipments found"
              />
            ),
          }}
          onRowClick={(params) => props.shipmentSelected(params.row)}
        />
      </div>
    </div>
  );
};
