import React from 'react';

import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { PurchaseOrder } from '../../../types';
import './PurchaseOrderSidebar.css';
import { formatCurrency } from '../../../util/currency';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { formatDate } from '../../../util/date';

interface Props {
  isLoading: boolean;
  purchaseOrder: PurchaseOrder | undefined;
}

export const PurchaseOrderSidebar = (props: Props) => {
  const { isLoading, purchaseOrder } = props;

  if (isLoading || purchaseOrder === undefined) {
    return (
      <Stack spacing={2} width="75%">
        {Array.from({ length: 6 }).map((_, i) => (
          <Box key={i}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2} className="po-sidebar">
      <Box>
        <Typography variant="overline">PO NUMBER</Typography>
        <Typography variant="h6">{purchaseOrder.po_number}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">STATUS</Typography>
        <StatusDisplay status={purchaseOrder.status} />
      </Box>
      <Box>
        <Typography variant="overline">Purchase Rep.</Typography>
        <Typography variant="h6">{purchaseOrder.representative}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">PO Type</Typography>
        <Typography variant="h6">{purchaseOrder.type}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">INCO Terms</Typography>
        <Typography variant="h6">{purchaseOrder.inco_terms}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">Payment Terms</Typography>
        <Typography variant="h6">{purchaseOrder.payment_terms}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">PO Total</Typography>
        <Typography variant="h6">
          {formatCurrency(purchaseOrder.currency, purchaseOrder.total)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="overline">Due By</Typography>
        <Typography variant="h6">
          {formatDate(purchaseOrder.due_date)}
        </Typography>
      </Box>
    </Stack>
  );
};
