import { User } from '../types';

const AuthTokenKeyName = 'authToken';
const AuthUserKeyName = 'authuser';

// pull auth token from local storage
export const getAuthToken = (): string | null => {
  return localStorage.getItem(AuthTokenKeyName);
};

// set auth token in local storage
export const setAuthToken = (authToken: string) => {
  localStorage.setItem(AuthTokenKeyName, authToken);
};

// remove auth token from local storage
export const removeAuthToken = () => {
  localStorage.removeItem(AuthTokenKeyName);
};

// get user info from local storage
export const getUserInfo = (): User | null => {
  const storedUser = localStorage.getItem(AuthUserKeyName);

  return storedUser ? JSON.parse(storedUser) : null;
};

// set user info in local storage
export const setUserInfo = (user: User) => {
  localStorage.setItem(AuthUserKeyName, JSON.stringify(user));
};

// remove user info from local storage
export const removeUserInfo = () => {
  localStorage.removeItem(AuthUserKeyName);
};
