import { Typography } from '@mui/material';
import React from 'react';

import './StatusDisplay.css';

interface Props {
  status: string;
}

export const StatusDisplay = (props: Props) => {
  const { status } = props;

  const statusClass = `status-bullet status-${status
    .toLowerCase()
    .replace(/ /g, '-')}`;

  return <Typography className={statusClass}>{status}</Typography>;
};
