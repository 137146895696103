import React from 'react';

import { useParams } from 'react-router-dom';

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import IconButton from '@mui/material/IconButton';

import { Document } from '../../../types';
import { useDownloadFile } from '../../../queries/fileQueries';
import { AllParams } from '../../../util/routes';

interface Props {
  document: Document;
}

export const DownloadDocumentAction = (props: Props) => {
  const params = useParams<AllParams>();

  const downloadFileMutation = useDownloadFile(params);

  const download = async (doc: Document) => {
    // get the signed url where we can download the file
    downloadFileMutation.mutate(doc.key, {
      onSuccess: (signedUrl) => {
        window.open(signedUrl, '_blank');
      },
    });
  };

  return (
    <IconButton
      title={`Download ${props.document.title}`}
      aria-label={`Download ${props.document.title}`}
      onClick={() => download(props.document)}
    >
      <CloudDownloadOutlinedIcon />
    </IconButton>
  );
};
