import React, { useMemo } from 'react';

import { ImageDetail, Part } from '../../../types';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { StatusDisplay } from '../status/StatusDisplay';
import { PartImage } from '../image/PartImage';
import { TaskStatus } from '../../tasks/status/TaskStatus';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { NoRowsOverlay } from '../../grid/NoRowsOverlay';

interface Props {
  isLoading: boolean;
  error?: any;
  partSelected: (part: Part) => void;
  parts: Part[] | undefined;
}

// https://www.sketch.com/s/ff34f2bf-839c-4ee3-bf54-d70d09dd5522/a/oY4ZOvQ
export const PartList = (props: Props) => {
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'thumbnail_image',
        headerName: 'Image',
        filterable: false,
        sortable: false,
        width: 75,
        renderCell: (
          params: GridRenderCellParams<Part, ImageDetail | undefined>
        ) => {
          return <PartImage image={params.value} />;
        },
      },
      {
        field: 'part_name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'part_number',
        headerName: 'Zipline Part Number',
        flex: 1,
      },
      {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1,
      },
      {
        field: 'open_tasks',
        headerName: 'Task Status',
        filterable: false,
        flex: 1.25,
        renderCell: (params: GridRenderCellParams) => {
          const part = params.row as Part;

          return (
            <TaskStatus
              openCount={part.open_tasks}
              doneCount={part.done_tasks}
            ></TaskStatus>
          );
        },
      },
      {
        field: 'part_state',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Part, string>) => {
          return <StatusDisplay status={params.value!}></StatusDisplay>;
        },
      },
    ],
    []
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          autoHeight
          rows={props.parts || []}
          columns={cols}
          getRowId={(row) => row.vendor_part_id}
          loading={props.isLoading}
          pageSizeOptions={[100]}
          hideFooterSelectedRowCount={true}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                error={props.error}
                noRowsMessage="No parts found"
              />
            ),
          }}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'open_tasks',
                  sort: 'desc',
                },
              ],
            },
          }}
          onRowClick={(params) => props.partSelected(params.row)}
        />
      </div>
    </div>
  );
};
