import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { AuthContext } from '../../auth/AuthContext';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuthLogin } from '../../queries/userQueries';
import { useSnackbar } from 'notistack';
import { requiresLogin } from '../../util/auth';
import { RoleNames } from '../../types';
import { LinkRouter } from '../theme/LinkRouter';
import { PortalFunctionality } from '../../util/features';

export default function Login() {
  const [auth, setAuth] = React.useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  const loginMutation = useAuthLogin();

  const loginRequired = requiresLogin(auth);

  if (!loginRequired) {
    // user already authenticated, redirect to entry or vendor list
    if (auth.vendor?.vendor_id) {
      return <Navigate to={`/vendor/${auth.vendor.vendor_id}/parts`} replace />;
    } else {
      return <Navigate to={`/vendors`} replace />;
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const loginInfo = {
      email: data.get('email'),
      password: data.get('password'),
    };

    let emailString = (loginInfo.email || '') as string;
    const passwordString = (loginInfo.password || '') as string;

    // call API to login
    try {
      const user = await loginMutation.mutateAsync({
        email: emailString,
        password: passwordString,
      });

      setAuth(user);
    } catch {
      enqueueSnackbar(`Login failed, please try again`, { variant: 'error' });
    }
  };

  if (loginMutation.isSuccess && loginMutation.data) {
    const authenticatedUser = loginMutation.data;

    // if we have a return URL, redirect to that
    const returnUrl = searchParams.get('returnUrl');

    if (returnUrl) {
      return <Navigate to={returnUrl} />;
    }

    // zipliners don't pick a vendor but go to a full parts list
    if (authenticatedUser.role === RoleNames.Zipliner) {
      return <Navigate to={`/vendors/parts`} />;
    } else {
      // supplier, so just go to their vendor's parts list
      if (authenticatedUser.vendor?.vendor_id) {
        return (
          <Navigate
            to={`/vendor/${authenticatedUser.vendor.vendor_id}/parts`}
          />
        );
      } else {
        // this should never happen, since every vendor should have a vendor_id
        return (
          <div>
            Your login was successful but we do not have a valid supplier listed
            for your account. Please inform your zipline contact.
          </div>
        );
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Supplier Portal Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            helperText="Login with your portal email address"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ 'data-testid': 'password' }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {loginMutation.isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Sign In'
            )}
          </Button>
          <Stack direction="row" justifyContent="space-between" mt={4}>
            {PortalFunctionality.showAccountCreation ? (
              <LinkRouter to="/createaccount">
                Need A Portal Account?
              </LinkRouter>
            ) : (
              <div></div>
            )}
            <LinkRouter to="/forgotpassword">Forgot Password?</LinkRouter>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
