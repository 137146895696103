import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { OrderedPart, PurchaseOrder } from '../../../types';
import { PurchaseOrderParams } from '../../../util/routes';
import {
  ScheduleChanges,
  useScheduleQuery,
  useScheduleUpdate,
} from '../../../queries/purchaseOrderQueries';
import { QuoteScheduleList } from './QuoteScheduleList';
import { PortalFunctionality } from '../../../util/features';

interface Props {
  purchaseOrder: PurchaseOrder | undefined;
  orderedPart: OrderedPart | undefined;
  onComplete: (success: boolean, part: OrderedPart | undefined) => void;
}

// view blanket schedule in a modal & optionally edit it
export const QuoteScheduleModal = (props: Props) => {
  const params = useParams<PurchaseOrderParams>();

  // keep track of staged changed until updated button is clicked
  const [scheduleChanges, setScheduleChanges] = React.useState<
    ScheduleChanges[]
  >([]);

  const { isLoading: isScheduleLoading, data: scheduleData } = useScheduleQuery(
    params,
    props.orderedPart?.purchase_order_line_id || '',
    props.orderedPart !== undefined
  );

  const scheduleUpdate = useScheduleUpdate(params);

  const { enqueueSnackbar } = useSnackbar();

  const open = props.orderedPart !== undefined;

  // clear out any staged changes when the modal is closed
  React.useEffect(() => {
    if (!open) {
      setScheduleChanges([]);
    }
  }, [open]);

  // add or update scheduled changes. values can be undefined to indicate no change to mirror API
  const queueScheduleChange = (
    purchase_order_line_id: string,
    msa_line_id: string,
    actual_quantity?: number,
    supplier_ready_date?: Date
  ) => {
    const existingChanges = [...scheduleChanges];

    const existingChange = existingChanges.find(
      (c) => c.msa_line_id === msa_line_id
    );

    if (existingChange) {
      if (actual_quantity) {
        existingChange.actual_quantity = actual_quantity;
      }
      if (supplier_ready_date) {
        existingChange.supplier_ready_date = supplier_ready_date;
      }
    } else {
      existingChanges.push({
        purchase_order_line_id,
        msa_line_id,
        actual_quantity,
        supplier_ready_date,
      });
    }

    setScheduleChanges(existingChanges);
  };

  const handleSubmit = () => {
    scheduleUpdate.mutate(scheduleChanges, {
      onSuccess: () => {
        enqueueSnackbar('Schedule updated', { variant: 'success' });
        props.onComplete(true, props.orderedPart);
      },
      onError: () => {
        enqueueSnackbar('Error updating schedule', { variant: 'error' });
      },
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={() =>
        props.orderedPart && props.onComplete(false, props.orderedPart)
      }
      aria-labelledby="msa-dialog-title"
    >
      {isScheduleLoading ? (
        <DialogTitle>Loading...</DialogTitle>
      ) : (
        <>
          <DialogTitle id="msa-dialog-title">
            Blanket Schedule for Part {props.orderedPart?.part_number} of PO{' '}
            {props.purchaseOrder?.po_number}
            <IconButton
              aria-label="close"
              onClick={() => props.onComplete(false, props.orderedPart)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <QuoteScheduleList
              schedules={scheduleData || []}
              queueScheduleChange={queueScheduleChange}
            />
          </DialogContent>
          <DialogActions>
            {PortalFunctionality.allowPurchaseOrderEdits ? (
              <Button
                disabled={
                  scheduleChanges.length === 0 || scheduleUpdate.isLoading
                }
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {scheduleUpdate.isLoading ? 'Updating...' : 'Update Schedule'}
              </Button>
            ) : (
              <Button
                onClick={() => props.onComplete(false, props.orderedPart)}
              >
                Close
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
