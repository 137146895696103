import React from 'react';

import { AuthContext } from '../../auth/AuthContext';
import { Navigate } from 'react-router-dom';
import { RoleNames } from '../../types';

export const VendorPicker = () => {
  const [authenticatedUser] = React.useContext(AuthContext);

  if (authenticatedUser.role === RoleNames.Zipliner) {
    // zipliners don't pick a vendor but go to a full parts list
    return <Navigate to={`/vendors/parts`} replace />;
  } else {
    // supplier, so just go to their vendor's parts list
    return (
      <Navigate
        to={`/vendor/${authenticatedUser.vendor?.vendor_id}/parts`}
        replace
      />
    );
  }
};
