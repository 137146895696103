import React from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import { Typography } from '@mui/material';

import { AuthContext } from '../../auth/AuthContext';
import { requiresLogin } from '../../util/auth';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

interface Breadcrumb {
  name: string;
  path: string;
}

export const NavCrumbs = () => {
  const [auth] = React.useContext(AuthContext);

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const crumbs = GetCumbs(pathnames);

  // hide the nav if the user isn't logged in yet
  if (requiresLogin(auth)) {
    return null;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="primary" to="/">
        Home
      </LinkRouter>
      {crumbs.map((crumb, index) => {
        const last = index === crumbs.length - 1;

        return last ? (
          <Typography color="text.primary" key={crumb.path}>
            {crumb.name}
          </Typography>
        ) : (
          <LinkRouter
            underline="hover"
            color="primary"
            to={crumb.path}
            key={crumb.path}
          >
            {crumb.name}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

const GetCumbs = (pathNames: string[]) => {
  // we want to ignore the first 2 params (vendor info)
  if (pathNames.length < 3) {
    return [];
  }

  //Find out which of the 3 sections we're in
  const section = pathNames[2];

  // let's store and return the crumbs
  const crumbs: Breadcrumb[] = [];

  // loop through the pathnames and build the crumbs
  pathNames.forEach((_, index) => {
    // ignore the first 2 params (vendor info)
    if (index < 2) {
      return;
    }

    const path = `/${pathNames.slice(0, index + 1).join('/')}`;

    if (section === 'parts') {
      if (index === 2) {
        crumbs.push({ name: 'Parts List', path });
      } else if (index === 3) {
        crumbs.push({ name: 'Part Detail', path });
      } else if (index === 4) {
        crumbs.push({
          name: 'Task Detail',
          path,
        });
      } else if (index === 5) {
        crumbs.push({
          name: 'Subtask Detail',
          path,
        });
      }
    } else if (section === 'purchaseorders') {
      if (index === 2) {
        crumbs.push({ name: 'Purchase Orders', path });
      } else if (index === 3) {
        crumbs.push({
          name: 'Purchase Order Detail',
          path,
        });
      }
    } else if (section === 'deliveries') {
      if (index === 2) {
        crumbs.push({ name: 'Deliveries', path });
      } else if (index === 3) {
        // special case for the create delivery page
        if (pathNames[3] === 'shipment') {
          crumbs.push({
            name: 'Create Delivery',
            path,
          });
        } else {
          crumbs.push({
            name: 'Delivery Detail',
            path,
          });
        }
      } else if (index === 4) {
        // remove the create crumb and replace it with the edit crumb
        crumbs.pop();
        crumbs.push({
          name: 'Edit Delivery',
          path,
        });
      }
    }
  });

  return crumbs;
};
