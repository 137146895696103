import { useState } from 'react';

import { Badge, Box, IconButton } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { OrderedPart } from '../../../types';
import { DeliveryLinksModal } from '../links/DeliveryLinksModal';
import { DeliveryDocumentsModal } from '../documents/DeliveryDocumentsModal';

interface Props {
  part: OrderedPart;
  updatePart: (part: OrderedPart) => void;
}

export const DeliveryLineItemActions = (props: Props) => {
  // track if we are viewing one of the actions
  const [actionView, setActionView] = useState<
    'urls' | 'documents' | undefined
  >();

  const unsetView = () => setActionView(undefined);

  return (
    <>
      <Box display="flex" height="56px">
        <IconButton aria-label="cart" onClick={() => setActionView('urls')}>
          <Badge badgeContent={props.part.urls.length} color="secondary">
            <TagIcon color="action" />
          </Badge>
        </IconButton>
        <IconButton
          aria-label="cart"
          onClick={() => setActionView('documents')}
        >
          <Badge badgeContent={props.part.documents.length} color="secondary">
            <AttachFileIcon color="action" />
          </Badge>
        </IconButton>
      </Box>
      <DeliveryLinksModal
        part={actionView === 'urls' ? props.part : undefined}
        updatePart={props.updatePart}
        onComplete={unsetView}
      />
      <DeliveryDocumentsModal
        part={actionView === 'documents' ? props.part : undefined}
        updatePart={props.updatePart}
        onComplete={unsetView}
      />
    </>
  );
};
