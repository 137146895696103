import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrderedPart, Shipment } from '../types';
import {
  apiBodyAsJson,
  apiFetchWithError,
} from '../util/api';
import { DeliveryParams } from '../util/routes';

// Mutation to create a new scheduled pickup request
export const usePickupRequestCreate = (params: Partial<DeliveryParams>) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ shipment }: { shipment: Shipment }) => {
      // pickup requests work on the PO part line level, so we need to flatten the shipment
      const pickupRequests = shipment.purchase_orders.flatMap((po) =>
        po.parts.flatMap((part) => part)
      );

      // for each package, set package_id to 0 since they are all new
      // TODO: for now, set actual pickup location and initial status manually
      for (let i = 0; i < pickupRequests.length; i++) {
        const part = pickupRequests[i];

        part.actual_pickup_location_id = part.pickup_location_id;
        part.status = 'ready';

        for (let k = 0; k < part.shipment_packages.length; k++) {
          const pkg = part.shipment_packages[k];

          pkg.package_id = '0';
        }
      }

      // now we have a valid shipment, let's send it to the API, one call per part
      const promises = [];
      for(let i = 0; i < pickupRequests.length; i++) {
        const part = pickupRequests[i];

        promises.push(apiFetchWithError<OrderedPart>(
          `vendors/${params.vendorId}/schedule-pickup-requests/`,
          {
            method: 'POST',
            body: apiBodyAsJson(part),
          }
        ));
      }

      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        // force refresh of shipments for this vendor
        queryClient.invalidateQueries(['pickup-requests', params.vendorId]);
      },
    }
  );
};
