// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-sidebar h6 {
  color: var(--primary-font);
  line-height: 1;
  font-size: 1.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/purchaseOrders/sidebar/PurchaseOrderSidebar.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".po-sidebar h6 {\n  color: var(--primary-font);\n  line-height: 1;\n  font-size: 1.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
