import React from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';

import { Comment } from '../../../types';
import { UserAvatar } from '../../users/avatar/UserAvatar';
import { sanitizeHTML } from '../../../util/sanitize';
import { formatDatetime } from '../../../util/date';

interface Props {
  vendorId: string;
  comment: Comment;
}

export const CommentRow = (props: Props) => {
  const { comment } = props;

  return (
    <Box
      sx={{
        my: 2,
        mx: 'auto',
        px: 2,
      }}
    >
      <Grid container wrap="nowrap" alignItems="center" spacing={2}>
        <Grid item>
          <UserAvatar
            vendorId={props.vendorId}
            userId={comment.user_id}
          ></UserAvatar>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">{comment.user_name}</Typography>
            <Typography variant="subtitle2">
              {formatDatetime(comment.date)}
            </Typography>
          </Stack>
          <Typography>{sanitizeHTML(comment.comment)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
