import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Document, OrderedPart, Package, PurchaseOrder } from '../../../types';
import Button from '@mui/material/Button';
import { DeliveryLineItem } from '../lineItems/DeliveryLineItem';

interface Props {
  purchaseOrder: PurchaseOrder;
  packages: Package[];
  setPurchaseOrder: (po: PurchaseOrder) => void;
}

const getBlankDeliveryLineItem = (props: Props): OrderedPart => {
  return {
    purchase_order_line_id: '',
    msa_line_id: '',
    part_number: '',
    quantity: 0,
    urls: [] as string[],
    documents: [] as Document[],
    // default to first package if there is only one
    shipment_packages:
      props.packages.length === 1 ? [{ ...props.packages[0] }] : [],
  } as OrderedPart;
};

export const DeliveryGrid = (props: Props) => {
  const { parts } = props.purchaseOrder;

  useEffect(() => {
    // if we have no parts, add a blank one
    if (parts.length === 0) {
      props.setPurchaseOrder({
        ...props.purchaseOrder,
        parts: [getBlankDeliveryLineItem(props)],
      });
    }
  }, [parts.length, props]);

  const addPart = () => {
    props.setPurchaseOrder({
      ...props.purchaseOrder,
      parts: [...parts, getBlankDeliveryLineItem(props)],
    });
  };

  const updatePart = (part: OrderedPart, index: number) => {
    const newParts = [...parts];
    newParts[index] = part;
    props.setPurchaseOrder({
      ...props.purchaseOrder,
      parts: newParts,
    });
  };

  return (
    <Grid container item spacing={2}>
      {/* TODO: inco terms needs to be returned from list API if we want to use it here */}
      {/* <Grid item xs={12}>
        <Typography variant="subtitle1">Inco Terms {props.purchaseOrder.inco_terms}</Typography>
      </Grid> */}
      <Grid container item xs={12} spacing={2}>
        {parts.map((part, index) => (
          <DeliveryLineItem
            key={index}
            purchase_order_id={props.purchaseOrder.purchase_order_id}
            part={part}
            packages={props.packages}
            updatePart={(p: OrderedPart) => updatePart(p, index)}
          ></DeliveryLineItem>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={addPart}>
          Add Item
        </Button>
      </Grid>
    </Grid>
  );
};
