import React from 'react';

import List from '@mui/material/List';
import { Skeleton } from '@mui/material';

import { Section } from '../../layout/section/Section';
import { Activity } from '../../../types';
import { ActivityRow } from '../row/ActivityRow';
import { PortalFunctionality } from '../../../util/features';

interface Props {
  activities: Activity[] | undefined;
}

export const ActivityView = (props: Props) => {
  // const params = useParams<AllParams>();

  if (!PortalFunctionality.showActivity) {
    return null;
  }

  return (
    <Section header={'Activity'}>
      {props.activities === undefined ? (
        <Skeleton />
      ) : (
        <List dense>
          {props.activities.filter(a => a.summary).map((activity: Activity) => (
            <ActivityRow key={activity.activity_id} activity={activity} />
          ))}
        </List>
      )}
    </Section>
  );
};
