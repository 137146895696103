import React from 'react';

import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Container, Paper } from '@mui/material';

import MainTheme from './components/theme/MainTheme';
import Login from './components/login/Login';
import { Header } from './components/header/Header';
import { NavBar } from './components/header/NavBar';
import Colors from './components/theme/Colors';
import { NavCrumbs } from './components/header/NavCrumbs';

import { User } from './types';
import { PrivateRoute } from './auth/SecureRoute';
import { AuthContext } from './auth/AuthContext';
import { HomeRedirect } from './auth/HomeRedirect';

import { PartListPage } from './pages/parts/PartListPage';
import { PartViewPage } from './pages/parts/PartViewPage';
import { TaskViewPage } from './pages/parts/TaskViewPage';
import { SubtaskViewPage } from './pages/parts/SubtaskViewPage';
import { PurchaseOrderListPage } from './pages/purchaseOrders/PurchaseOrderListPage';
import { PurchaseOrderViewPage } from './pages/purchaseOrders/PurchaseOrderViewPage';
import { ErrorPage } from './pages/error/ErrorPage';
import { PartLayout } from './pages/parts/PartLayout';
import { VendorPicker } from './pages/vendors/VendorPickerPage';
import { ZiplinerPartPickerPage } from './pages/vendors/ZiplinerPartPickerPage';
import { DeliveryModifyPage } from './pages/deliveries/DeliveryModifyPage';
import { DeliveryListPage } from './pages/deliveries/DeliveryListPage';
import { DeliveryViewPage } from './pages/deliveries/DeliveryViewPage';
import { ZiplinerPurchaseOrderPickerPage } from './pages/vendors/ZiplinerPurchaseOrderPickerPage';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { CreateAccount } from './pages/auth/CreateAccount';
import { ResetPassword } from './pages/auth/ResetPassword';
import { PartSearchPage } from './pages/parts/PartSearchPage';
import { PurchaseOrderSearchPage } from './pages/purchaseOrders/PurchaseOrderSearchPage';

// Default refetches whenever the window regains focus.
// Change to false to disable the behavior
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  const storedUser = localStorage.getItem('authuser');

  const [authenticatedUser, setAuthenticatedUser] = React.useState<User>(
    storedUser ? JSON.parse(storedUser) : { user_id: '', token: '' }
  );

  return (
    <AuthContext.Provider value={[authenticatedUser, setAuthenticatedUser]}>
      <MainTheme>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <Header user={authenticatedUser}></Header>
            <NavBar></NavBar>
            <Paper sx={{ backgroundColor: Colors.header, minHeight: '100vh' }}>
              <Container maxWidth="xl">
                <Box pt={2} pb={4}>
                  <NavCrumbs />
                </Box>
                <Routes>
                  <Route path="*" element={<div>Not found</div>} />
                  <Route path="/error" element={<ErrorPage />} />

                  {/* Home page will redirect user to login, vendor list, or parts page depending on user info */}
                  <Route path="/" element={<HomeRedirect />} />

                  {/* if single vendor is not provided, allow selection of vendors */}
                  <Route path="/vendors" element={<PrivateRoute />}>
                    <Route index element={<VendorPicker />} />
                    <Route path="parts" element={<ZiplinerPartPickerPage />} />
                    <Route
                      path="purchaseorders"
                      element={<ZiplinerPurchaseOrderPickerPage />}
                    />
                  </Route>

                  <Route path="/vendor/:vendorId" element={<PrivateRoute />}>
                    {/* parts routes */}
                    <Route index element={<Navigate to="parts" />} />
                    <Route path="parts" element={<PartSearchPage />} />
                    <Route path="partlist" element={<PartListPage />} />
                    <Route path="parts/:partId" element={<PartLayout />}>
                      <Route index element={<PartViewPage />} />
                      <Route path=":taskId" element={<TaskViewPage />} />
                      <Route
                        path=":taskId/:subTaskId"
                        element={<SubtaskViewPage />}
                      />
                    </Route>

                    {/* deliveries routes */}
                    <Route path="deliveries" element={<DeliveryListPage />} />
                    <Route
                      path="deliveries/:shipmentId"
                      element={<DeliveryViewPage />}
                    />
                    <Route
                      path="deliveries/shipment/:shipmentId?"
                      element={<DeliveryModifyPage />}
                    />

                    {/* purchase orders routes */}
                    <Route
                      path="purchaseOrders"
                      element={<PurchaseOrderSearchPage />}
                    />
                    <Route
                      path="purchaseOrderList"
                      element={<PurchaseOrderListPage />}
                    />

                    <Route
                      path="purchaseOrders/:purchaseOrderId"
                      element={<PurchaseOrderViewPage />}
                    />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route path="/createaccount" element={<CreateAccount />} />
                  <Route path="/new-password" element={<ResetPassword />} />
                </Routes>
              </Container>
            </Paper>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MainTheme>
    </AuthContext.Provider>
  );
}

export default App;
