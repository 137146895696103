// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nav-user .signout-link {
  align-self: flex-end;
  text-decoration: underline;
  color: var(--tertiary-font);
}
#nav-user .signout-link:hover {
  color: var(--color-zip-red);
}
#nav-user {
  text-align: right;
}
@media (max-width: 900px) {
  #nav-user-details {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/header/NavUser.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,0BAA0B;EAC1B,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["#nav-user .signout-link {\n  align-self: flex-end;\n  text-decoration: underline;\n  color: var(--tertiary-font);\n}\n#nav-user .signout-link:hover {\n  color: var(--color-zip-red);\n}\n#nav-user {\n  text-align: right;\n}\n@media (max-width: 900px) {\n  #nav-user-details {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
