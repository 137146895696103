import React from 'react';

import { Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Task } from '../../../types';
import { TaskParams } from '../../../util/routes';
import { useTaskStatusUpdate } from '../../../queries/partQueries';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { SquaredLeftButton } from '../../theme/SquaredLeftButton';

interface Props {
  status: string;
  task: Task;
  onTaskChanged: (subTask: Task) => void;
  label: string;
  loadingLabel: string;
}

export const SetTaskStatus = (props: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false);

  const params = useParams<TaskParams>();

  const updateTaskStatus = useTaskStatusUpdate(params);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setIsProcessing(true);

    try {
      await updateTaskStatus.mutateAsync(props.status);

      enqueueSnackbar(`${props.task.title} set to ${props.status}`, {
        variant: 'success',
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Stack direction={'row'} justifyContent="flex-end">
      <Box className="status-box" display="flex" alignItems="center">
        <StatusDisplay status={props.task.status} />
      </Box>
      <SquaredLeftButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        {isProcessing ? props.loadingLabel : props.label}
      </SquaredLeftButton>
    </Stack>
  );
};
