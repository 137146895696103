import React from 'react';

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Section } from '../../layout/section/Section';
import { CommentEntry } from '../entry/CommentEntry';
import { CommentRow } from '../row/CommentRow';
import { Comment } from '../../../types';
import { CommentsLoading } from '../loading/CommentsLoading';
import { AuthContext } from '../../../auth/AuthContext';
import { useCommentCreate } from '../../../queries/commonQueries';
import { AllParams } from '../../../util/routes';

interface Props {
  comments: Comment[] | undefined;
}

export const CommentView = (props: Props) => {
  const params = useParams<AllParams>();

  const [user] = React.useContext(AuthContext);

  const commentCreateMutation = useCommentCreate(params);

  const onSubmit = async (text: string) => {
    // submit comment to server and update on success
    const newComment: Comment = {
      comment_id: '',
      comment: text,
      user_id: user.user_id,
      user_name: user.name,
      date: new Date(),
    };

    return await commentCreateMutation.mutateAsync(newComment);
  };

  return (
    <Section header="Comments">
      <Box mb={4}>
        <CommentEntry vendorId={params.vendorId!} author={user} onSubmit={onSubmit}></CommentEntry>
      </Box>
      {props.comments === undefined ? (
        <CommentsLoading />
      ) : (
        (props.comments || []).map((comment: Comment, i) => (
          <CommentRow
            key={i}
            vendorId={params.vendorId!}
            comment={comment}
          ></CommentRow>
        ))
      )}
    </Section>
  );
};
