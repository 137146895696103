import React, { useMemo } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';

import { Task } from '../../../types';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { ShortDateFormat } from '../../../util/grid';
import { sanitizeHTML } from '../../../util/sanitize';
import { NoRowsOverlay } from '../../grid/NoRowsOverlay';

interface Props {
  isLoading: boolean;
  error?: any;
  taskSelected: (task: Task) => void;
  tasks: Task[] | undefined;
}

export const TaskList = (props: Props) => {
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        valueFormatter: (params) => {
          return sanitizeHTML(params.value);
        },
        flex: 1.25,
      },
      {
        type: 'date',
        field: 'start_date',
        headerName: 'Start',
        flex: 1,
        valueFormatter: ShortDateFormat,
      },
      {
        type: 'date',
        field: 'end_date',
        headerName: 'End',
        flex: 1,
        valueFormatter: ShortDateFormat,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Task, string>) => {
          return <StatusDisplay status={params.value!}></StatusDisplay>;
        },
      },
    ],
    []
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          autoHeight
          rows={props.tasks || []}
          columns={cols}
          pageSizeOptions={[100]}
          getRowId={(row) => row.task_id}
          hideFooterSelectedRowCount={true}
          loading={props.isLoading}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                error={props.error}
                noRowsMessage="No tasks found"
              />
            ),
          }}
          onRowClick={(params) => props.taskSelected(params.row)}
        />
      </div>
    </div>
  );
};
