import React from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Colors from '../../theme/Colors';

interface Props {
  openCount: number;
  doneCount: number;
}

const TaskStatusHash: any = {
  open: Colors.open,
  done: Colors.done,
};

export const TaskStatus = (props: Props) => {
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label={props.openCount}
        sx={{ backgroundColor: TaskStatusHash['open'] }}
      />
      <Chip
        label={props.doneCount}
        sx={{ backgroundColor: TaskStatusHash['done'] }}
      />
    </Stack>
  );
};
