import React from 'react';

import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import { OrderedPart, Package } from '../../../types';
import {
  usePurchaseOrderQuery,
  useScheduleQuery,
} from '../../../queries/purchaseOrderQueries';
import { PurchaseOrderParams } from '../../../util/routes';
import { DeliveryLineItemActions } from './DeliveryLineItemActions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  purchase_order_id: string;
  part: OrderedPart;
  packages: Package[];
  updatePart: (part: OrderedPart) => void;
}

export const DeliveryLineItem = (props: Props) => {
  const params = useParams<PurchaseOrderParams>();

  // purchase order might not be part of the route, so we'll set it
  const lineItemParams: PurchaseOrderParams = {
    vendorId: params.vendorId || '',
    purchaseOrderId: props.purchase_order_id,
  };

  // load up the PO info so we can pull all available parts
  const { isLoading: isPurchaseOrderLoading, data: purchaseOrder } =
    usePurchaseOrderQuery(lineItemParams);

  const { isLoading: isScheduleLoading, data: schedules } = useScheduleQuery(
    lineItemParams,
    props.part.purchase_order_line_id,
    !!props.part.purchase_order_line_id
  );

  // when packages change, update the part so we stay in sync
  React.useEffect(() => {
    props.updatePart({
      ...props.part,
      shipment_packages: props.packages.filter((p) =>
        props.part.shipment_packages?.some((sp) => sp.package_id === p.package_id)
      ),
    });
  }, [props, props.packages, props.part, props.updatePart]);

  const handlePartChange = (event: SelectChangeEvent) => {
    // when part changes, reset other line fields
    props.updatePart({
      ...props.part,
      purchase_order_line_id: event.target.value,
      msa_line_id: '',
      quantity: 0,
    });
  };

  const handleScheduleChange = (event: SelectChangeEvent) => {
    const selectedSchedule = schedules?.find(
      (s) => s.msa_line_id === event.target.value
    );

    if (!selectedSchedule) {
      return;
    }

    // TODO: eventually we want to leave out most of the part info, but it's required for now
    // TODO: we also can't send anything back empty so we fake what we don't know
    const updatedPart: OrderedPart = {
      ...props.part,
      msa_line_id: selectedSchedule.msa_line_id,
      schedule_line_id: selectedSchedule.msa_line_id,
      mode_id: selectedSchedule.mode_id,
      pickup_location_id: selectedSchedule.pickup_location_id,
      supplier_ready_date: selectedSchedule.supplier_ready_date || new Date().toLocaleDateString(),
      required_ready_date: selectedSchedule.required_ready_date || new Date().toLocaleDateString(),
      need_by_date: selectedSchedule.need_by_date || new Date().toLocaleDateString(),
      schedule_quantity: selectedSchedule.schedule_quantity,
      dropship_address_id: selectedSchedule.dropship_address_id || selectedSchedule.pickup_location_id,
    };

    // when we update the schedule, default the quantity
    updatedPart.quantity = updatedPart.actual_quantity =
      selectedSchedule.schedule_quantity || 0;

    props.updatePart(updatedPart);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedPart: OrderedPart = {
      ...props.part,
      quantity: +event.target.value,
      actual_quantity: +event.target.value,
    };
    props.updatePart(updatedPart);
  };

  const handlePackagesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as Package[] | string[] | string;

    // value is always an array with newest selection as a string as the last element
    if (Array.isArray(value)) {
      const packages = value
        .map((p) => props.packages.find((pkg) => pkg.package_id === p))
        .filter((p) => p) as Package[];

      props.updatePart({
        ...props.part,
        shipment_packages: packages,
      });
    }
  };

  if (isPurchaseOrderLoading) {
    return <></>;
  }

  return (
    <>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel id="part-select-label">Choose Part</InputLabel>
          <Select
            labelId="part-select-label"
            id="part-select"
            value={props.part.purchase_order_line_id}
            label="Choose Part"
            onChange={handlePartChange}
          >
            {purchaseOrder?.parts.map((part) => (
              <MenuItem
                key={part.purchase_order_line_id}
                value={part.purchase_order_line_id}
              >
                {part.product_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            id="schedule-select-label"
            error={
              props.part.purchase_order_line_id !== '' &&
              schedules?.length === 0
            }
          >
            Choose Schedule
          </InputLabel>
          <Select
            labelId="schedule-select-label"
            id="schedule-select"
            disabled={
              props.part.purchase_order_line_id === '' || isScheduleLoading
            }
            value={props.part.msa_line_id}
            label="Choose Schedule"
            onChange={handleScheduleChange}
          >
            {schedules?.map((schedule) => (
              <MenuItem key={schedule.msa_line_id} value={schedule.msa_line_id}>
                {new Date(schedule.need_by_date).toLocaleDateString()}:{' '}
                {schedule.schedule_quantity} units
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {props.part.purchase_order_line_id === ''
              ? 'Select a part to show available schedules'
              : isScheduleLoading
              ? 'Loading...'
              : ''}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Quantity"
            required
            disabled={props.part.msa_line_id === ''}
            value={props.part.quantity || ''}
            onChange={handleQuantityChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="packages-multiple-checkbox-label">
            Packages
          </InputLabel>
          <Select
            labelId="packages-multiple-checkbox-label"
            id="packages-multiple-checkbox"
            multiple
            value={props.part.shipment_packages?.map((p) => p.package_id) || []}
            onChange={handlePackagesChange}
            input={<OutlinedInput label="Packages" />}
            renderValue={(selectedPackageIds) =>
              // find packages for the selected package ids
              props.packages
                .filter((p) => selectedPackageIds.includes(p.package_id))
                .map((p) => p.package_reference)
                .join(', ')
            }
            MenuProps={MenuProps}
          >
            {props.packages.map((pkg) => (
              <MenuItem key={pkg.package_id} value={pkg.package_id}>
                <Checkbox
                  checked={
                    props.part.shipment_packages?.some(
                      (sp) => sp.package_id === pkg.package_id
                    ) || false
                  }
                />
                <ListItemText primary={pkg.package_reference} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Choose at least one package to ship this part in
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <DeliveryLineItemActions
          part={props.part}
          updatePart={props.updatePart}
        />
      </Grid>
    </>
  );
};
