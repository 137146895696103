import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { requiresLogin } from '../util/auth';

import { AuthContext } from './AuthContext';

export function PrivateRoute() {
  const [authenticatedUser] = React.useContext(AuthContext);

  const location = useLocation();

  const loginRequired = requiresLogin(authenticatedUser);

  // if the user needs to login, let's keep track of their current location so we can redirect them back after login
  const returnUrl = location.pathname + location.search;

  return loginRequired ? (
    <Navigate to={`/login?returnUrl=${encodeURIComponent(returnUrl)}`} />
  ) : (
    <Outlet />
  );
}
