import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

import Spinner from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import { OrderedPart, Part } from '../../../types';
import { useGenerateDeliveryPackage } from '../../../queries/partQueries';
import { AllParams } from '../../../util/routes';
import { useSnackbar } from 'notistack';

interface Props {
  part: Part | OrderedPart;
  level: 'single' | 'multi';
  label?: string;
}

export const PartPackageDownload = (props: Props) => {
  const params = useParams<AllParams>();

  const { enqueueSnackbar } = useSnackbar();

  const generateDownloadLink = useGenerateDeliveryPackage({
    vendorId: params.vendorId,
    partId: props.part.vendor_part_id,
  });

  const handleDownload = () => {
    generateDownloadLink.mutate(
      { part_rev: props.part.part_rev, level: props.level },
      {
        onSuccess: (response) => {
          window.open(response.download_url, '_blank');
        },
        onError: () => {
          enqueueSnackbar('Error generating download link', {
            variant: 'error',
          });
        },
      }
    );
  };

  if (!props.part.part_rev) {
    return props.label ? (
      <Button variant="outlined" fullWidth startIcon={<FileDownloadOffIcon />} disabled>
        {props.label}
      </Button>
    ) : (
      <Button variant="outlined" disabled>
        <FileDownloadOffIcon />
      </Button>
    );
  }

  return (
    <>
      {props.label ? (
        <Button
          variant="outlined"
          fullWidth
          onClick={handleDownload}
          startIcon={<DownloadIcon />}
        >
          {generateDownloadLink.isLoading
            ? 'Generating Package...'
            : props.label}
        </Button>
      ) : (
        <Button sx={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={handleDownload}>
          {generateDownloadLink.isLoading ? (
            <Spinner size={20} />
          ) : (
            <DownloadIcon />
          )}
        </Button>
      )}
    </>
  );
};
