import { Task } from '../types';

// assumes task can either be open or closed

export const isTaskClosed = (status: string): boolean => {
  return status === taskStatuses.Done || status === taskStatuses.Cancelled;
};

export const isTaskOpen = (status: string): boolean => {
  return !isTaskClosed(status);
};

export const taskClosedCount = (tasks: Task[]): number => {
  return tasks.filter((task) => isTaskClosed(task.status)).length;
};

export const taskOpenCount = (tasks: Task[]): number => {
  return tasks.length - taskClosedCount(tasks);
};

export const partStatuses = {
  Approved: 'Approved',
  Disqualified: 'Disqualified',
  Initial: 'Initial',
  InterimApproved: 'Interim Approved',
  NotForProduction: 'Not For Production',
  PhaseOut: 'Phase Out',
  Preferred: 'Preferred',
};

export const taskStatuses = {
  New: 'New',
  InProgress: 'In Progress',
  Submitted: 'Submitted',
  Done: 'Done',
  Cancelled: 'Cancelled',
};

export const purchaseOrderStatuses = {
  RFQ: 'RFQ',
  RFQSent: 'RFQ Sent',
  PurchaseOrder: 'Purchase Order',
  Closed: 'Closed',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
};

export type PurchaseOrderQuoteAction = 'Rejected' | 'Cancelled' | 'Confirmed';
