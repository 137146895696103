import React from 'react';

import { Box, Button, Menu, MenuItem, Stack, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSnackbar } from 'notistack';

import { StatusDisplay } from '../status/StatusDisplay';

import './ChangeStatus.css';

interface Props {
  statuses: string[];
  currentStatus: string;
  changeStatus: (status: string) => Promise<void>;
}

const SquaredLeftButton = styled(Button)({
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  marginLeft: '-1px',
});

// core component for changing task and subtask statuses
export const ChangeStatus = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (status: string) => {
    setAnchorEl(null);

    // menu close can return html as status, so ensure it's a string before we update
    if (status && typeof status === 'string') {
      console.log('changing task status to', status);
      setIsProcessing(true);

      try {
        await props.changeStatus(status);

        enqueueSnackbar(`Status changed to ${status}`, { variant: 'success' });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <Stack direction={'row'} justifyContent="flex-end">
      <Box className="status-box" display="flex" alignItems="center">
        <StatusDisplay status={props.currentStatus} />
      </Box>
      <SquaredLeftButton
        id="status-button"
        variant="contained"
        color="primary"
        aria-controls={open ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={isProcessing}
        endIcon={<ArrowDropDownIcon />}
      >
        {isProcessing ? 'Changing Status...' : 'Change Status'}
      </SquaredLeftButton>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
      >
        {props.statuses.map((status) => (
          <MenuItem
            key={status}
            disabled={status === props.currentStatus}
            onClick={() => handleClose(status)}
          >
            <StatusDisplay status={status} />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
