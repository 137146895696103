import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Shipment } from '../types';
import {
  apiBodyAsJson,
  apiFetchQueryWithError,
  apiFetchWithError,
} from '../util/api';
import { DeliveryParams, VendorParams } from '../util/routes';

// API Hooks for each query

// TODO: refactor shipment queries out of purchase order queries

// Return all shipments for a specific vendor
export const useShipmentsQuery = (params: Partial<VendorParams>) =>
  useQuery(['shipments', params.vendorId], () =>
    apiFetchQueryWithError<Shipment[]>(`vendors/${params.vendorId}/shipments`)
  );

export const useShipmentQuery = (params: Partial<DeliveryParams>) =>
  useQuery(
    ['shipments', params.vendorId, params.shipmentId],
    () =>
      apiFetchQueryWithError<Shipment>(
        `vendors/${params.vendorId}/shipments/${params.shipmentId}`
      ),
    {
      enabled: !!params.shipmentId,
    }
  );

// Mutation to create or update a shipment
export const useShipmentCreateOrUpdate = (params: Partial<DeliveryParams>) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ shipment }: { shipment: Shipment }) => {

      // we need to clear out the package IDs before sending to the API if they are new packages
      for (let i = 0; i < shipment.purchase_orders.length; i++) {
        const po = shipment.purchase_orders[i];

        for (let j = 0; j < po.parts.length; j++) {
          const part = po.parts[j];

          for (let k = 0; k < part.shipment_packages.length; k++) {
            const pkg = part.shipment_packages[k];

            if (pkg.package_id.startsWith('NEWID_')) {
              pkg.package_id = '0';
            }
          }
        }
      }

      // now we have a valid shipment, let's send it to the API
      return apiFetchWithError<Shipment>(
        `vendors/${params.vendorId}/shipments/` + (shipment.shipment_id || ''),
        {
          method: shipment.shipment_id ? 'PUT' : 'POST',
          body: apiBodyAsJson(shipment),
        }
      );
    },
    {
      onSuccess: () => {
        // force refresh of shipments for this vendor
        queryClient.invalidateQueries(['shipments', params.vendorId]);
      },
    }
  );
};
