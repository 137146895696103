import { useMemo } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Schedule } from '../../../types';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { NoRows } from '../../grid/NoRows';
import { FormControl, Typography } from '@mui/material';
import { UncontrolledDatePicker } from './UncontrolledDatePicker';
import { formatDateOnly, getDateFromString } from '../../../util/date';
import { PortalFunctionality } from '../../../util/features';

interface Props {
  schedules: Schedule[];
  queueScheduleChange: (
    purchase_order_line_id: string,
    msa_line_id: string,
    actual_quantity?: number,
    supplier_ready_date?: Date
  ) => void;
}

// view blanket schedule in a modal & optionally edit it
export const QuoteScheduleList = (props: Props) => {
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'schedule_quantity',
        headerName: 'Req Qty',
        flex: 1,
      },
      /* ---- commenting out as per https://github.com/ZiplineTeam/odoo_v15/pull/2736#discussion_r1396476873 ---- */
      // {
      //   field: 'actual_quantity',
      //   headerName: 'Actual Qty',
      //   sortable: PortalFunctionality.allowPurchaseOrderEdits ? false : true,
      //   flex: 1,
      //   renderCell: (params: GridRenderCellParams<Schedule, string>) => {
      //     const row = params.row as Schedule;

      //     if (PortalFunctionality.allowPurchaseOrderEdits === false) {
      //       return <>{row.actual_quantity}</>
      //     }
      //     return (
      //       <FormControl>
      //         <TextField
      //           autoFocus
      //           defaultValue={row.actual_quantity || 0}
      //           onChange={(e) =>
      //             props.queueScheduleChange(
      //               row.purchase_order_line_id,
      //               row.msa_line_id,
      //               Number(e.target.value),
      //               undefined
      //             )
      //           }
      //           type="number"
      //           name="actual_quantity"
      //           label="Actual Qty"
      //           variant="outlined"
      //           required
      //           inputProps={{ step: '1.0', min: '0.00' }}
      //         />
      //       </FormControl>
      //     );
      //   },
      // },
      {
        field: 'required_ready_date',
        headerName: 'Req Date',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<Schedule, string>) => {
          const row = params.row as Schedule;

          return formatDateOnly(row.required_ready_date);
        },
      },
      {
        field: 'supplier_ready_date',
        headerName: 'Ready Date',
        sortable: PortalFunctionality.allowPurchaseOrderEdits ? false : true,
        flex: 1.25,
        renderCell: (params: GridRenderCellParams<Schedule, string>) => {
          const row = params.row as Schedule;

          const date = row.supplier_ready_date ? getDateFromString(row.supplier_ready_date) : new Date();

          if (PortalFunctionality.allowPurchaseOrderEdits === false) {
            return <>{formatDateOnly(row.supplier_ready_date)}</>
          }

          return (
            <FormControl>
              <UncontrolledDatePicker
                label="Ready Date"
                defaultValue={date}
                onChange={(newValue) =>
                  props.queueScheduleChange(
                    row.purchase_order_line_id,
                    row.msa_line_id,
                    undefined,
                    newValue || undefined
                  )
                }
              />
            </FormControl>
          );
        },
      },
      {
        field: 'pickup_location',
        headerName: 'Pickup loc',
        sortable: false,
        flex: 1,
      },
      {
        field: 'destination',
        headerName: 'Destination loc',
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Schedule, string>) => {
          const row = params.row as Schedule;

          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {row.destination}
            </Typography>
          );
        },
      },
      {
        field: 'mode',
        headerName: 'Ship Mode',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
      },
    ],
    [props]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ width: '100%', height: 500 }}>
        <StyledDataGrid
          getRowHeight={() => 104} // default to double normal size
          rows={props.schedules || []}
          columns={cols}
          getRowId={(row) => row.msa_line_id}
          hideFooterSelectedRowCount={true}
          slots={{
            noRowsOverlay: () => <NoRows>No schedules found</NoRows>,
          }}
        />
      </div>
    </LocalizationProvider>
  );
};
