import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { OrderedPart } from '../../../types';
import { PurchaseOrderParams } from '../../../util/routes';
import { usePurchaseOrderPartUpdate } from '../../../queries/purchaseOrderQueries';
import { useSnackbar } from 'notistack';

interface Props {
  orderedPart: OrderedPart | undefined;
  onComplete: (success: boolean, part: OrderedPart) => void;
}

export const QuoteEditModal = (props: Props) => {
  const params = useParams<PurchaseOrderParams>();

  const { enqueueSnackbar } = useSnackbar();

  const purchaseOrderPartUpdate = usePurchaseOrderPartUpdate(params);

  const open = props.orderedPart !== undefined;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!props.orderedPart) {
      return; // we will always have an ordered part when submitting
    }

    const data = new FormData(event.currentTarget);

    // make sure unitPrice is a number
    const unitPrice = parseFloat(data.get('unitPrice') as string);

    if (isNaN(unitPrice) === false) {
      // valid, let's save
      const updatedPart: OrderedPart = {
        ...props.orderedPart,
        unit_price: unitPrice,
      };
      purchaseOrderPartUpdate.mutate(updatedPart, {
        onSuccess: () => {
          enqueueSnackbar('Price updated', { variant: 'success' });
          props.onComplete(true, updatedPart);
        },
        onError: () => {
          enqueueSnackbar('Error updating price', { variant: 'error' });
        },
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() =>
        props.orderedPart && props.onComplete(false, props.orderedPart)
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        {props.orderedPart && (
          <Box component="form" onSubmit={handleSubmit} sx={modalBodyStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Change unit price of {props.orderedPart.product_name} (no:{' '}
              {props.orderedPart.part_number})
            </Typography>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Unit Price
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                autoFocus
                defaultValue={props.orderedPart.unit_price}
                type="number"
                name="unitPrice"
                required
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                inputProps={{ step: '0.01', min: '0.00' }}
                label="Unit Price"
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={purchaseOrderPartUpdate.isLoading}
            >
              {purchaseOrderPartUpdate.isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        )}
      </div>
    </Modal>
  );
};

const modalBodyStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
