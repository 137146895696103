import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './Section.css';

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
}

// Display a header and then children as items
export const Section = (props: Props) => {
  // if our header is just a string, wrap as Typography h5, otherwise let it render as passed
  const simpleStringHeader = typeof props.header === 'string';

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems={'center'} spacing={2}>
        <span className="divider-border"></span>
        {simpleStringHeader ? (
          <Typography variant="h5">{props.header}</Typography>
        ) : (
          props.header
        )}
      </Stack>
      <Stack direction="row" alignItems={'center'} spacing={2}>
        <span className="divider-empty"></span>
        <Box sx={{ width: '100%' }}>{props.children}</Box>
      </Stack>
    </Stack>
  );
};
