import React, { useMemo } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { OrderedPart, PurchaseOrder } from '../../../types';
import { formatCurrency } from '../../../util/currency';
import { NoRows } from '../../grid/NoRows';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { QuoteEditModal } from './QuoteEditModal';
import { QuoteScheduleModal } from '../schedule/QuoteScheduleModal';
import { PartPackageDownload } from '../../parts/download/PartPackageDownload';

interface Props {
  isLoading: boolean;
  purchaseOrder: PurchaseOrder | undefined;
}

export const QuoteEditList = (props: Props) => {
  const [priceEditRow, setPriceEditRow] = React.useState<OrderedPart>();
  const [scheduleEditRow, setScheduleEditRow] = React.useState<OrderedPart>();

  const editComplete = () => {
    // save happens in the modals, so we just need to close them
    setPriceEditRow(undefined);
    setScheduleEditRow(undefined);
  };

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'product_name',
        headerName: 'Part Name',
        flex: 2,
      },
      {
        field: 'part_number',
        headerName: 'Zipline Part Number',
        flex: 1.25,
      },
      {
        field: "part_rev",
        headerName: "Revision"
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        flex: 0.75,
      },
      {
        field: 'schedule_quantity',
        headerName: 'Scheduled',
        flex: 0.75,
      },
      {
        type: 'number',
        field: 'unit_price',
        headerName: 'Unit Price',
        headerAlign: 'left',
        align: 'left',
        flex: 0.75,
        // TODO: We want to remove editing pricing for MVP -- we can add it back later
        // renderCell: (params: GridRenderCellParams) => {
        //   // Unit price should only be editable when the PO status is RFQ Sent
        //   if (props.purchaseOrder?.status === purchaseOrderStatuses.RFQSent) {
        //     return (
        //       <>
        //         {formatCurrency(params.row.currency, params.row.unit_price)}
        //         <IconButton
        //           aria-label="edit"
        //           size="small"
        //           onClick={() => setPriceEditRow(params.row)}
        //         >
        //           <EditIcon fontSize="small" />
        //         </IconButton>
        //       </>
        //     );
        //   } else {
        //     return formatCurrency(params.row.currency, params.row.unit_price);
        //   }
        // },
        renderCell: (params) =>
          formatCurrency(params.row.currency, params.row.unit_price),
        valueGetter: (params) => params.row.unit_price,
      },
      {
        type: 'number',
        field: 'total_price',
        headerName: 'Total',
        headerAlign: 'left',
        align: 'left',
        flex: 0.75,
        renderCell: (params) =>
          formatCurrency(params.row.currency, params.row.total_price),
        valueGetter: (params) => params.row.total_price,
      },
      {
        field: 'Actions',
        headerName: 'MSA',
        sortable: false,
        filterable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams<OrderedPart, string>) => {
          // MSA should only show if has_schedule is true
          if (params.row.has_schedule) {
            return (
              <IconButton
                aria-label="view msa"
                onClick={() => setScheduleEditRow(params.row)}
              >
                <PendingActionsIcon />
              </IconButton>
            );
          }
        },
      },
      {
        field: 'delivery_package',
        headerName: 'Package',
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<OrderedPart, string>) => (
          <PartPackageDownload part={params.row} level='single' />
        ),
      },
    ],
    []
  );

  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <StyledDataGrid
            autoHeight
            rows={props.purchaseOrder?.parts || []}
            loading={props.isLoading}
            columns={cols}
            getRowId={(row) => row.purchase_order_line_id}
            pageSizeOptions={[100]}
            hideFooterSelectedRowCount={true}
            slots={{
              noRowsOverlay: () => <NoRows>No purchase order parts found</NoRows>,
            }}
          />
        </div>
      </div>
      <QuoteEditModal
        orderedPart={priceEditRow}
        onComplete={editComplete}
      ></QuoteEditModal>
      <QuoteScheduleModal
        purchaseOrder={props.purchaseOrder}
        orderedPart={scheduleEditRow}
        onComplete={editComplete}
      ></QuoteScheduleModal>
    </>
  );
};
