import React from 'react';

import { Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { SubTask } from '../../../types';
import { SubtaskParams } from '../../../util/routes';
import { useSubTaskStatusUpdate } from '../../../queries/partQueries';
import { StatusDisplay } from '../../parts/status/StatusDisplay';
import { SquaredLeftButton } from '../../theme/SquaredLeftButton';

interface Props {
  status: string;
  subTask: SubTask;
  onSubTaskChanged: (subTask: SubTask) => void;
  label: string;
  loadingLabel: string;
}

export const SetSubTaskStatus = (props: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false);

  const params = useParams<SubtaskParams>();

  const updateSubTaskStatus = useSubTaskStatusUpdate(params);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setIsProcessing(true);

    try {
      await updateSubTaskStatus.mutateAsync(props.status);

      enqueueSnackbar(`${props.subTask.title} set to ${props.status}`, {
        variant: 'success',
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Stack direction={'row'} justifyContent="flex-end">
      <Box className="status-box" display="flex" alignItems="center">
        <StatusDisplay status={props.subTask.status} />
      </Box>
      <SquaredLeftButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        {isProcessing ? props.loadingLabel : props.label}
      </SquaredLeftButton>
    </Stack>
  );
};
