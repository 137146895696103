// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-box {
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid rgba(232, 33, 14, 0.5);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/parts/changeStatus/ChangeStatus.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;IACxC,0BAA0B;IAC1B,6BAA6B;IAC7B,+BAA+B;AACnC","sourcesContent":[".status-box {\n    padding: 5px 15px;\n    border-radius: 4px;\n    border: 1px solid rgba(232, 33, 14, 0.5);\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    border-right-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
