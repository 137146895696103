import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  AlertTitle,
  AlertColor,
  LinearProgress,
  TextField,
} from '@mui/material';

interface Props {
  id: string;
  keepMounted: boolean;
  open: boolean;
  title: string;
  content: string;
  requireReason: boolean;
  processing: boolean;
  severity?: AlertColor;
  onClose: (confirmed: boolean, reason?: string) => void;
}

export const ConfirmationDialog = (props: Props) => {
  const {
    open,
    onClose,
    title,
    content,
    processing,
    severity,
    requireReason,
    ...other
  } = props;

  const [reason, setReason] = React.useState('');

  const handleCancel = () => {
    setReason(''); // reset reason
    onClose(false);
  };

  const handleConfirm = () => {
    if (!requireReason || reason.length > 0) {
      setReason(''); // reset reason
      onClose(true, reason);
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="sm"
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {processing ? (
          <LinearProgress />
        ) : (
          <>
            <Alert severity={props.severity || 'warning'}>
              <AlertTitle>{title}</AlertTitle>
              {content}
            </Alert>
            {requireReason && (
              <TextField
                autoFocus
                required
                error={reason.length === 0}
                helperText={reason.length === 0 ? 'Reason Required' : ''}
                margin="dense"
                label="Reason"
                type="text"
                fullWidth
                variant="standard"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            )}
          </>
        )}
      </DialogContent>
      {!processing && (
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
