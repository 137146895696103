import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Document, OrderedPart } from '../../../types';
import { DocumentUpload } from '../../documents/upload/DocumentUpload';
import { DocumentListSimple } from '../../documents/list/DocumentListSimple';

interface Props {
  part: OrderedPart | undefined;
  updatePart: (part: OrderedPart) => void;
  onComplete: (success: boolean, part: OrderedPart | undefined) => void;
}

// view documents associated with a part & optionally edit them
export const DeliveryDocumentsModal = (props: Props) => {
  const open = props.part !== undefined;

  const handleAddDocument = (document: Document) => {
    if (props.part !== undefined) {
      props.updatePart({
        ...props.part,
        documents: [document, ...props.part.documents],
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => props.part && props.onComplete(false, props.part)}
      aria-labelledby="docs-dialog-title"
    >
      <>
        <DialogTitle id="docs-dialog-title">
          Delivery Documents for #{props.part?.part_number}
          <IconButton
            aria-label="close"
            onClick={() => props.onComplete(false, props.part)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DocumentUpload
            onDocumentUploaded={handleAddDocument}
            documentType="delivery"
          />
          <Box mt={4} maxWidth="100%">
            <DocumentListSimple
              isLoading={false}
              documents={props.part?.documents || []}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => props.part && props.onComplete(false, props.part)}
          >
            Close
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
