import { GridValueFormatterParams } from '@mui/x-data-grid';
import { formatDate } from './date';

export const ShortDateFormat = (params: GridValueFormatterParams<Date>) => {
  if (params.value) {
    return formatDate(params.value);
  }

  return '';
};
