import React from 'react';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { ActivityView } from '../../components/activity/view/ActivityView';
import { PurchaseOrderSidebar } from '../../components/purchaseOrders/sidebar/PurchaseOrderSidebar';
import { CommentView } from '../../components/comments/view/CommentView';
import { QuoteEditList } from '../../components/quote/edit/QuoteEditList';
import { ConfirmQuoteButton } from '../../components/quote/confirm/ConfirmQuoteButton';
import { usePurchaseOrderQuery } from '../../queries/purchaseOrderQueries';
import { PurchaseOrderParams } from '../../util/routes';
import { RestrictedView } from '../../auth/RestrictedView';
import { PurchaseOrder, RoleNames } from '../../types';
import { ApproveQuoteButton } from '../../components/quote/approve/ApproveQuoteButton';
import { purchaseOrderStatuses } from '../../helpers/status';
import { DocumentUpload } from '../../components/documents/upload/DocumentUpload';
import { DocumentList } from '../../components/documents/list/DocumentList';
import { PortalFunctionality } from '../../util/features';

// TODO: Should we make a sub-component for just active state or keep the if/else
export const PurchaseOrderViewPage = () => {
  const params = useParams<PurchaseOrderParams>();

  // vendorId is guaranteed to be present in the params because it is required in the route
  const vendorId = params.vendorId!;

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: purchaseOrder,
  } = usePurchaseOrderQuery(params);

  if (isLoading === false && purchaseOrder === undefined) {
    navigate('/notfound');
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sm={2}>
        <PurchaseOrderSidebar
          isLoading={isLoading}
          purchaseOrder={purchaseOrder}
        />
      </Grid>
      <Grid item xs={8} sm={10}>
        <MainPaper>
          <Section
            header={
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: '100% ' }}
                spacing={2}
              >
                <Typography variant="h5">Purchase Order Parts</Typography>
                {purchaseOrder && (
                  <Box>
                    {renderActionButtons(vendorId, purchaseOrder, navigate)}
                  </Box>
                )}
              </Stack>
            }
          >
            <Box>
              {/* TODO: determine when quote should not be editable, if ever.
                For now, everyone can see and edit list at all times
              */}
              <QuoteEditList
                isLoading={isLoading}
                purchaseOrder={purchaseOrder}
              />
              {/* <RestrictedView roles={[RoleNames.Zipliner]}>
                    <PurchaseOrderPartsList
                      isLoading={isLoading}
                      parts={purchaseOrder?.parts}
                    />
                  </RestrictedView> */}
            </Box>
          </Section>
          {purchaseOrder?.status === purchaseOrderStatuses.RFQSent && (
            <Box mt={6}>
              <Section header="Documents">
                <Box>
                  <DocumentUpload
                    onDocumentUploaded={() => {}}
                    documentType="purchase-order"
                  />
                  <Box mt={4} maxWidth="75%">
                    <DocumentList
                      isLoading={isLoading}
                      error={error}
                      documents={purchaseOrder?.documents}
                      documentType="purchase-order"
                    />
                  </Box>
                </Box>
              </Section>
            </Box>
          )}

          <Box mt={6}>
            <CommentView comments={purchaseOrder?.comments} />
          </Box>
          <Box mt={6}>
            <ActivityView activities={purchaseOrder?.activities} />
          </Box>
        </MainPaper>
      </Grid>
    </Grid>
  );
};

const renderActionButtons = (
  vendorId: string,
  order: PurchaseOrder,
  navigate: NavigateFunction
) => {
  const { status } = order;

  if (status === purchaseOrderStatuses.PurchaseOrder && PortalFunctionality.showDeliveries) {
    return (
      <>
        <Button
          variant="contained"
          color="success"
          onClick={() =>
            navigate(
              `/vendor/${vendorId}/deliveries/shipment?purchaseOrderId=${order.purchase_order_id}`
            )
          }
          sx={{ marginRight: '1rem;' }}
          startIcon={<LocalShippingIcon />}
        >
          Add Deliveries
        </Button>
      </>
    );
  } else if (status === purchaseOrderStatuses.RFQSent && PortalFunctionality.allowPurchaseOrderEdits) {
    return (
      <>
        <RestrictedView roles={[RoleNames.Supplier]}>
          <ConfirmQuoteButton
            vendorId={vendorId}
            order={order}
          ></ConfirmQuoteButton>
        </RestrictedView>
        <RestrictedView roles={[RoleNames.Zipliner]}>
          <ApproveQuoteButton
            vendorId={vendorId}
            order={order}
          ></ApproveQuoteButton>
        </RestrictedView>
      </>
    );
  }
};
