import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Comment, ObjectWithCommentsAndActivities } from '../types';
import { apiBodyAsJson, apiFetchEmptyWithError } from '../util/api';
import { AllParams } from '../util/routes';

// helper to get query key and url from params
// query key is key of containing object, since it is what needs to be updated
const getQueryKeyAndUrl = (type: string, params: Partial<AllParams>) => {
  let queryKey = [params.vendorId];
  let queryUrl = `vendors/${params.vendorId}/`;

  if (params.purchaseOrderId) {
    // PO comments only if ponumber is provided
    queryKey = ['purchase-orders', ...queryKey, params.purchaseOrderId];

    queryUrl += `purchase-orders/${params.purchaseOrderId}/`;
  } else {
    // else it's a comment on part, task, or subtask
    queryKey = ['part', ...queryKey];

    if (params.partId) {
      queryKey.push(params.partId);
      queryUrl += `parts/${params.partId}/`;
    }

    if (params.taskId) {
      queryKey.push(params.taskId);
      queryUrl += `tasks/${params.taskId}/`;
    }

    if (params.subTaskId) {
      queryKey.push(params.subTaskId);
      queryUrl += `subtasks/${params.subTaskId}/`;
    }
  }

  return { queryKey, queryUrl };
};

export const useCommentCreate = (params: Partial<AllParams>) => {
  const queryClient = useQueryClient();

  const { queryKey, queryUrl } = getQueryKeyAndUrl('comments', params);

  return useMutation(
    (comment: Comment) =>
      apiFetchEmptyWithError(queryUrl + 'comments', {
        method: 'POST',
        body: apiBodyAsJson(comment),
      }),
    {
      onSuccess: (_, variables) => {
        const comment = variables;
        // get the object and insert the new comment
        const queryObject: ObjectWithCommentsAndActivities | undefined =
          queryClient.getQueryData(queryKey);
        if (queryObject) {
          queryClient.setQueryData(queryKey, {
            ...queryObject,
            comments: [comment, ...queryObject.comments],
          });
        }
      },
    }
  );
};