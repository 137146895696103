import React from 'react';

import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { Part } from '../../../types';
import { PartImage } from '../image/PartImage';
import { StatusDisplay } from '../status/StatusDisplay';
import { PartPackageDownload } from '../download/PartPackageDownload';
import './PartsSidebar.css';

interface Props {
  isLoading: boolean;
  part: Part | undefined;
}

export const PartsSidebar = (props: Props) => {
  if (props.isLoading || props.part === undefined) {
    return (
      <Stack spacing={2} width="75%">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" width={210} height={118} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" className="parts-sidebar" spacing={2}>
      <Box>
        <Typography variant="overline">ZIPLINE PART NUMBER</Typography>
        <Typography variant="h6">{props.part.part_number}</Typography>
      </Box>
      <Box>
        <Typography variant="overline">STATUS</Typography>
        <StatusDisplay status={props.part.part_state} />
      </Box>
      <PartImage image={props.part.main_image} />
      <Box sx={{ width: '100%', alignContent: 'center' }}>
        <Typography variant="overline">DELIVERY PACKAGES</Typography>
        <PartPackageDownload
          part={props.part}
          level="single"
          label="Single Level"
        />
        <Box sx={{ height: 8 }} />
        <PartPackageDownload
          part={props.part}
          level="multi"
          label="Multi Level"
        />
      </Box>
    </Stack>
  );
};
