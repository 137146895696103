import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { PurchaseOrderSearchParams } from '../../types';
import { useDebounce } from '../../util/debounce';
import { PurchaseOrderList } from '../../components/purchaseOrders/list/PurchaseOrderList';
import { useAllPurchaseOrdersQuery } from '../../queries/purchaseOrderQueries';
import { PurchaseOrderParams } from '../../util/routes';

// Allows searching through current vendor purchase orders
export const PurchaseOrderSearchPage = () => {
  const params = useParams<PurchaseOrderParams>();

  const navigate = useNavigate();

  const [search, setSearch] = React.useState<PurchaseOrderSearchParams>({
    vendor: params.vendorId || '',
    vendor_name: '',
    part_number: '',
    po_number: '',
    supplier_code: '',
    state: '',
  });

  const debouncedSearch = useDebounce(search, 500);

  const query = useAllPurchaseOrdersQuery(debouncedSearch);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };

  return (
    <MainPaper>
      <Section header={'Purchase Order List'}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" sx={{ width: '33%', flexShrink: 0 }}>
              Search Filters
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Entering multiple terms will result in an AND search.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2} maxWidth="40%">
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Supplier Code"
                    name="supplier_code"
                    fullWidth
                    value={search.supplier_code}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="PO Number"
                    name="po_number"
                    fullWidth
                    value={search.po_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Zipline Part Number"
                    name="part_number"
                    fullWidth
                    value={search.part_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Status"
                    name="state"
                    fullWidth
                    value={search.state}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <PurchaseOrderList
          isLoading={query.isLoading && query.isFetching}
          error={query.error}
          purchaseOrders={query.data || []}
          purchaseOrderSelected={(po) =>
            navigate(
              `/vendor/${po.vendor_id}/purchaseorders/${po.purchase_order_id}`
            )
          }
        />
      </Section>
    </MainPaper>
  );
};
