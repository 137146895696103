import { User } from '../types';

// returns true if user is not logged in or if their session expires within the next 1 day
export const requiresLogin = (user: User) => {
  if (!user.isLoggedIn) {
    return true;
  }

  if (!user.sessionExpiresAt) {
    return true;
  }

  const sessionExpiresAtDate = new Date(user.sessionExpiresAt);

  const oneDayFromNow = new Date();
  oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

  return sessionExpiresAtDate < oneDayFromNow;
};
