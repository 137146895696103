import React from 'react';

import Avatar from '@mui/material/Avatar';

import { useUserInfoQuery } from '../../../queries/userQueries';

interface Props {
  vendorId: string;
  userId: string;
}

export const UserAvatar = (props: Props) => {
  const { isLoading, data: profile } = useUserInfoQuery(
    props.vendorId,
    props.userId
  );

  if (isLoading) {
    return <Avatar />;
  }

  return (
    <Avatar
      alt={profile?.profile_picture.alt}
      src={profile?.profile_picture.src}
    />
  );
};
