import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { PartList } from '../../components/parts/list/PartList';
import { MainPaper } from '../../components/theme/MainPaper';
import { useAllPartsQuery } from '../../queries/partQueries';
import { PartsSearchParams } from '../../types';
import { useDebounce } from '../../util/debounce';
import { PartParams } from '../../util/routes';

// Allows searching through all parts for current vendor
export const PartSearchPage = () => {
  const params = useParams<PartParams>();

  const navigate = useNavigate();

  const [search, setSearch] = React.useState<PartsSearchParams>({
    vendor: params.vendorId || '',
    vendor_name: '',
    supplier_code: '',
    name: '',
    number: '',
    revision: '',
    state: '',
    sre: '',
  });

  const debouncedSearch = useDebounce(search, 500);

  const query = useAllPartsQuery(debouncedSearch);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };

  return (
    <MainPaper>
      <Section header={'Part List'}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" sx={{ width: '33%', flexShrink: 0 }}>
              Search Filters
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Entering multiple terms will result in an AND search.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2} maxWidth="40%">
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Part Name"
                    name="name"
                    fullWidth
                    value={search.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Supplier Code"
                    name="supplier_code"
                    fullWidth
                    value={search.supplier_code}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Zipline Part Number"
                    name="number"
                    fullWidth
                    value={search.number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Status"
                    name="state"
                    fullWidth
                    value={search.state}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Zipline Quality Owner"
                    name="sre"
                    fullWidth
                    value={search.sre}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <PartList
          isLoading={query.isLoading && query.isFetching}
          error={query.error}
          parts={query.data || []}
          partSelected={(part) =>
            navigate(`/vendor/${part.vendor_id}/parts/${part.vendor_part_id}`)
          }
        />
      </Section>
    </MainPaper>
  );
};
