import React from 'react';
import { ImageDetail } from '../../../types';

import './PartImage.css';

interface Props {
  image: ImageDetail | undefined;
}

// show image if it exists, otherwise use 50x50 placeholder
export const PartImage = (props: Props) => {
  // assume square
  const size = props.image?.width || 50; // default to 50x50

  const placeholder = `https://via.placeholder.com/${size}`;

  var src = props.image?.src || placeholder;

  return (
    <img
      src={src}
      width={size}
      height={size}
      alt={props.image?.alt || 'Part Image'}
    />
  );
};
