import { Stack, Typography, Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { ShipmentList } from '../../components/deliveries/list/ShipmentList';
import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { useShipmentsQuery } from '../../queries/shipmentQueries';
import { DeliveryParams } from '../../util/routes';

export const DeliveryListPage = () => {
  const navigate = useNavigate();

  const params = useParams<DeliveryParams>();

  const query = useShipmentsQuery(params);

  return (
    <MainPaper>
      <Section
        header={
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100% ' }}
            spacing={2}
          >
            <Typography variant="h5">Deliveries</Typography>
            <Box>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  navigate(`/vendor/${params.vendorId}/deliveries/shipment`)
                }
                sx={{ marginRight: '1rem;' }}
                startIcon={<LocalShippingIcon />}
              >
                Add Delivery
              </Button>
            </Box>
          </Stack>
        }
      >
        <ShipmentList
          isLoading={query.isLoading}
          error={query.error}
          shipments={query.data || []}
          shipmentSelected={(shipment) => navigate(`${shipment.shipment_id}`)}
        />
      </Section>
    </MainPaper>
  );
};
