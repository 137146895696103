import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { OrderedPart } from '../../../types';

interface Props {
  part: OrderedPart | undefined;
  updatePart: (part: OrderedPart) => void;
  onComplete: (success: boolean, part: OrderedPart | undefined) => void;
}

// view urls associated with a part & optionally edit them
export const DeliveryLinksModal = (props: Props) => {
  const open = props.part !== undefined;

  const handleAddLink = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const link = data.get('link') as string;

    if (link && props.part !== undefined) {
      props.updatePart({
        ...props.part,
        urls: [link, ...props.part.urls],
      });
    }
  };

  const handleDeleteLink = (link: string) => {
    if (props.part !== undefined) {
      props.updatePart({
        ...props.part,
        urls: props.part.urls.filter((l) => l !== link),
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => props.part && props.onComplete(false, props.part)}
      aria-labelledby="Links-dialog-title"
    >
      <>
        <DialogTitle id="Links-dialog-title">
          Delivery Links for #{props.part?.part_number}
          <IconButton
            aria-label="close"
            onClick={() => props.onComplete(false, props.part)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Paper elevation={2} sx={{ paddingTop: '8px' }}>
            <Box component="form" onSubmit={handleAddLink}>
              <Grid
                container
                flex={1}
                p={2}
                justifyContent="space-between"
                alignItems={'center'}
              >
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    autoFocus
                    required
                    id="link"
                    name="link"
                    label="New Link"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs="auto">
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <List dense={false} sx={{ paddingTop: '24px' }}>
            {props.part?.urls.map((url, index) => (
              <React.Fragment key={`${url}-${index}`}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteLink(url)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <Link href={url} target="_blank" rel="noreferrer">
                    {url.length > 50 ? url.substring(0, 50) + '...' : url}
                  </Link>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => props.part && props.onComplete(false, props.part)}
          >
            Close
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
