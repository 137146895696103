import React from 'react';

import { RoleNames } from '../types';
import { AuthContext } from './AuthContext';

interface Props {
  children: React.ReactNode;
  roles: RoleNames[];
}

// Show children only if user has one of the roles
export const RestrictedView = (props: Props) => {
  const { children, roles } = props;

  const [authenticatedUser] = React.useContext(AuthContext);

  // find out if user has any of the roles
  const useHasRole = roles.some(
    (role) => authenticatedUser.role.localeCompare(role) === 0
  );

  if (useHasRole) {
    return <>{children}</>;
  } else {
    return null;
  }
};
