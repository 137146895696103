// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .parts-sidebar .MuiTypography-overline {
  color: var(--secondary-font);
} */
.parts-sidebar h6 {
  color: var(--primary-font);
  line-height: 1;
  font-size: 1.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/parts/sidebar/PartsSidebar.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,0BAA0B;EAC1B,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["/* .parts-sidebar .MuiTypography-overline {\n  color: var(--secondary-font);\n} */\n.parts-sidebar h6 {\n  color: var(--primary-font);\n  line-height: 1;\n  font-size: 1.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
