import React from 'react';

import { useParams } from 'react-router-dom';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box, CircularProgress, IconButton } from '@mui/material';

import { Document, DocumentType } from '../../../types';
import { useReplaceFile } from '../../../queries/fileQueries';
import { AllParams } from '../../../util/routes';

interface Props {
  document: Document;
  documentType: DocumentType;
}

export const ReplaceDocumentAction = (props: Props) => {
  const params = useParams<AllParams>();

  const replaceMutation = useReplaceFile(params, props.document, props.documentType);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      replaceMutation.mutate(file);

      // set file upload value back to empty so any selection will retrigger
      event.target.value = '';
    }
  };
  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <IconButton aria-label="download document" component="label">
        <CloudUploadOutlinedIcon />
        <input type="file" onChange={handleChange} hidden />
      </IconButton>
      {replaceMutation.isLoading && (
        <CircularProgress
          size={40}
          sx={{
            // color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-20px',
            marginLeft: '-20px',
          }}
        />
      )}
    </Box>
  );
};
