// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navbar {
  border-bottom: 1px solid var(--color-zip-border);
}
#navbar-main-links a {
  text-decoration: none;
  margin: 0px 12px;
  color: var(--primary-font);
}

#navbar-main-links a:hover {
  text-decoration: underline;
}

#navbar-main-links a.active {
  text-decoration: underline;
  text-decoration-color: #d74944;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/NavBar.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,0BAA0B;EAC1B,8BAA8B;AAChC","sourcesContent":["#navbar {\n  border-bottom: 1px solid var(--color-zip-border);\n}\n#navbar-main-links a {\n  text-decoration: none;\n  margin: 0px 12px;\n  color: var(--primary-font);\n}\n\n#navbar-main-links a:hover {\n  text-decoration: underline;\n}\n\n#navbar-main-links a.active {\n  text-decoration: underline;\n  text-decoration-color: #d74944;\n  text-underline-offset: 2px;\n  text-decoration-thickness: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
