import React from 'react';

import { Box, Grid } from '@mui/material';

import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import { ActivityView } from '../../components/activity/view/ActivityView';
import { CommentView } from '../../components/comments/view/CommentView';
import { DocumentUpload } from '../../components/documents/upload/DocumentUpload';
import { DocumentList } from '../../components/documents/list/DocumentList';
import { Document, RoleNames } from '../../types';
import { useParams } from 'react-router-dom';
import { SubTaskHeader } from '../../components/subtasks/header/SubTaskHeader';
import { RestrictedView } from '../../auth/RestrictedView';
import { ChangeSubTaskStatus } from '../../components/subtasks/changeStatus/ChangeSubTaskStatus';
import { SetSubTaskStatus } from '../../components/subtasks/setStatus/SetSubTaskStatus';
import { SubtaskParams } from '../../util/routes';
import { useSubTaskQuery } from '../../queries/partQueries';
import { taskStatuses } from '../../helpers/status';
import { PortalFunctionality } from '../../util/features';

export const SubtaskViewPage = () => {
  const [documents, setDocuments] = React.useState<Document[]>([]);

  const params = useParams<SubtaskParams>();

  const { isLoading, error, data: subTask } = useSubTaskQuery(params);

  React.useEffect(() => {
    if (subTask) {
      setDocuments(subTask.documents);
    }
  }, [subTask]);

  const onDocumentUploaded = (document: Document) => {
    setDocuments((d) => [document, ...d]);
  };

  return (
    <MainPaper>
      <Grid container mb={4}>
        <Grid item md={8}>
          <SubTaskHeader subTask={subTask} isLoading={isLoading} />
        </Grid>
        <Grid item md={4}>
          {subTask && (
            <>
              {/* Supplier can move new->in progress and in progress -> submitted */}
              {PortalFunctionality.allowSupplierTaskStatusChange &&
                subTask.status === taskStatuses.New && (
                  <RestrictedView roles={[RoleNames.Supplier]}>
                    <SetSubTaskStatus
                      status={taskStatuses.InProgress}
                      label="Move To In Progress"
                      loadingLabel="Moving to In Progress..."
                      subTask={subTask}
                      onSubTaskChanged={() => {}}
                    />
                  </RestrictedView>
                )}
              {PortalFunctionality.allowSupplierTaskStatusChange &&
                subTask.status === taskStatuses.InProgress && (
                  <RestrictedView roles={[RoleNames.Supplier]}>
                    <SetSubTaskStatus
                      status={taskStatuses.Submitted}
                      label="Submit"
                      loadingLabel="Submitting..."
                      subTask={subTask}
                      onSubTaskChanged={() => {}}
                    />
                  </RestrictedView>
                )}

              <RestrictedView roles={[RoleNames.Zipliner]}>
                <ChangeSubTaskStatus
                  subTask={subTask}
                  onSubTaskChanged={() => {}}
                />
              </RestrictedView>
            </>
          )}
        </Grid>
      </Grid>
      <Section header="Documents">
        <Box>
          <DocumentUpload
            onDocumentUploaded={onDocumentUploaded}
            documentType="subtask"
          />
          <Box mt={4} maxWidth="75%">
            <DocumentList
              isLoading={isLoading}
              error={error}
              documents={documents}
              documentType="subtask"
            />
          </Box>
        </Box>
      </Section>
      <Box mt={6}>
        <CommentView comments={subTask?.comments} />
      </Box>
      <Box mt={6}>
        <ActivityView activities={subTask?.activities} />
      </Box>
    </MainPaper>
  );
};
