import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { isValid } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

import { DeliveryParams } from '../../util/routes';
import { useShipmentQuery } from '../../queries/shipmentQueries';
import { Section } from '../../components/layout/section/Section';
import { MainPaper } from '../../components/theme/MainPaper';
import {
  ItemLabel,
  PropertyDisplay,
} from '../../components/theme/PropertyDisplay';
import { DownloadDocumentAction } from '../../components/documents/download/DownloadDocumentAction';

const COLSPAN = 3;

export const DeliveryViewPage = () => {
  const params = useParams<DeliveryParams>();

  const navigate = useNavigate();

  // TODO: there is no get shipment query yet
  const { isLoading, data: delivery } = useShipmentQuery(params);

  if (isLoading === false && delivery === undefined) {
    navigate('/notfound');
    return null;
  }

  if (isLoading || delivery === undefined) {
    return (
      <MainPaper>
        <Section
          header={<Typography variant="h5">Shipment</Typography>}
        >
          <Stack spacing={2}>
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={60} />
          </Stack>
        </Section>
      </MainPaper>
    );
  }

  // TODO: refactor info, package and po displays into separate components
  return (
    <MainPaper>
      <Section
        header={
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100% ' }}
            spacing={2}
          >
            <Typography variant="h5">
              Shipment #{delivery.shipment_id}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  navigate(
                    `/vendor/${params.vendorId}/deliveries/shipment/${params.shipmentId}`
                  )
                }
                startIcon={<EditIcon />}
                sx={{ marginRight: '1rem;' }}
              >
                Edit Delivery
              </Button>
            </Box>
          </Stack>
        }
      >
        <Box mt={6} mb={3}>
          <Typography variant="h6">Delivery Information</Typography>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={COLSPAN}>
              <PropertyDisplay property="Status" value="TODO" />
            </Grid>
            <Grid item xs={COLSPAN}>
              <PropertyDisplay
                property="DHL Tracking"
                value={delivery.tracking_number}
              />
            </Grid>
            <Grid item xs={12 - COLSPAN * 2} />
            <Grid item xs={COLSPAN}>
              <PropertyDisplay
                property="Contact Record"
                value={delivery.contact_name}
              />
            </Grid>
            <Grid item xs={COLSPAN}>
              <PropertyDisplay property="Shipping Docs" value={'TODO'} />
            </Grid>
            <Grid item xs={12 - COLSPAN * 2} />
            <Grid item xs={COLSPAN}>
              <PropertyDisplay
                property="Ready by Date"
                value={
                  isValid(delivery.ready_by_date)
                    ? delivery.ready_by_date.toLocaleDateString()
                    : 'Unknown'
                }
              />
            </Grid>
            <Grid item xs={12 - COLSPAN} />
            <Grid item xs={COLSPAN}>
              <PropertyDisplay
                property="Location"
                value={delivery.location_name}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        {/* <Box mt={3} mb={3}>
          {delivery.packages.map((pkg, index) => (
            <Box key={index}>
              <Typography variant="h6">Package #{index + 1}</Typography>
              <Grid container spacing={2} mt={0} mb={3}>
                <Grid item xs={COLSPAN}>
                  <PropertyDisplay
                    property="Piece Count"
                    value={pkg.count.toString()}
                  />
                </Grid>
                <Grid item xs={12 - COLSPAN} />
                <Grid item xs={COLSPAN}>
                  <PropertyDisplay
                    property="Weight"
                    value={`${pkg.weight} ${pkg.weight_unit}`}
                  />
                </Grid>
                <Grid item xs={12 - COLSPAN} />
                <Grid item xs={COLSPAN}>
                  <PropertyDisplay
                    property="Dimensions"
                    value={`${pkg.length} x ${pkg.width} x ${pkg.height} ${pkg.dimensions_unit}`}
                  />
                </Grid>
                <Grid item xs={12 - COLSPAN} />
              </Grid>
            </Box>
          ))}
        </Box> */}
        <Divider />
        <Box mt={3}>
          {delivery.purchase_orders.map((po, index) => (
            <Box key={index}>
              <Typography variant="h6">
                PO #{po.purchase_order_id} Delivery
              </Typography>

              {po.parts.map((part) => (
                <Grid
                  key={part.purchase_order_line_id}
                  container
                  spacing={2}
                  mt={0}
                  mb={3}
                >
                  <Grid item xs={COLSPAN}>
                    <PropertyDisplay property="Part" value={part.part_number} />
                  </Grid>
                  <Grid item xs={12 - COLSPAN} />
                  <Grid item xs={COLSPAN}>
                    <PropertyDisplay
                      property="Quantity"
                      value={part.quantity ? part.quantity.toString() : ''}
                    />
                  </Grid>
                  <Grid item xs={12 - COLSPAN} />
                  {/* links */}
                  <Grid item xs={COLSPAN * 2}>
                    <Grid container alignItems="center">
                      <Grid item xs={COLSPAN}>
                        <ItemLabel>Reference Links</ItemLabel>
                      </Grid>
                      <Grid item xs>
                        <List dense>
                          {part.urls.map((url, uidx) => (
                            <ListItem disableGutters key={uidx}>
                              <a href={url} target="_blank" rel="noreferrer">
                                {url}
                              </a>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12 - COLSPAN * 2} />
                  {/* docs */}
                  <Grid item xs={COLSPAN * 2}>
                    <Grid container alignItems="center">
                      <Grid item xs={COLSPAN}>
                        <ItemLabel>Documents</ItemLabel>
                      </Grid>
                      <Grid item xs>
                        <List dense>
                          {part.documents.map((doc) => (
                            <ListItem disableGutters key={doc.key}>
                              {doc.title}{' '}
                              <DownloadDocumentAction document={doc} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12 - COLSPAN * 2} />
                </Grid>
              ))}
            </Box>
          ))}
        </Box>
      </Section>
    </MainPaper>
  );
};
