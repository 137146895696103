import { createTheme, ThemeProvider } from '@mui/material/styles';

// TODO: define secondary color and more

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e8210e', // zipline red
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#2DB6DF',
    },
    warning: {
      main: '#FED541',
    },
    error: {
      main: '#e8210e',
    },
    success: {
      main: '#118F6D',
    },
  },
  typography: {
    fontFamily: ['plain', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'overline' },
          style: {
            color: `var(--secondary-font)`,
          },
        },
      ],
    },
  },
});

export default function MainTheme(props: any) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
