import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { PartList } from '../../components/parts/list/PartList';
import { MainPaper } from '../../components/theme/MainPaper';
import { usePartsQuery } from '../../queries/partQueries';
import { PartParams } from '../../util/routes';

export const PartListPage = () => {
  const navigate = useNavigate();

  const params = useParams<PartParams>();

  const query = usePartsQuery(params);

  return (
    <MainPaper>
      <Section header={'Part List'}>
        <PartList
          isLoading={query.isLoading}
          error={query.error}
          parts={query.data || []}
          partSelected={(part) => navigate(`${part.vendor_part_id}`)}
        />
      </Section>
    </MainPaper>
  );
};
