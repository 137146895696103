import {
  ShipmentMeasurementSystem,
  UnitOfMeasure,
  UnitOfMeasureCategory,
} from '../types';

export enum UnitOfMeasureCategories {
  Length = 'Length / Distance',
  Unit = 'Unit',
  Weight = 'Weight',
  Time = 'Working Time',
  Volume = 'Volume',
  Surface = 'Surface',
}

export enum ShipmentMeasurementSystemCategories {
  Length = 'Length',
  Weight = 'Weight',
  Volume = 'Volume',
}

export const getUnitsOfMeasureByCategory = (
  uoms: UnitOfMeasureCategory[],
  category: UnitOfMeasureCategories
) => {
  return uoms.find((uom) => uom.category_name === category)?.units ?? [];
};

export const getUnitsOfMeasureByShipmentSystems = (
  systems: ShipmentMeasurementSystem[],
  category: ShipmentMeasurementSystemCategories
): UnitOfMeasure[] => {
  return systems
    .map((system) => ({
      ...(system[
        category.toLowerCase() as keyof ShipmentMeasurementSystem
      ] as UnitOfMeasure),
      systemName: system.name,
    }))
    .filter((uom) => uom !== null);
};
