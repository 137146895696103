import React from 'react';

import Stack from '@mui/material/Stack';

interface Props {
  children: React.ReactNode;
}
export const NoRows = ({ children }: Props) => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    {children}
  </Stack>
);
