import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Section } from '../../components/layout/section/Section';
import { PurchaseOrderList } from '../../components/purchaseOrders/list/PurchaseOrderList';
import { MainPaper } from '../../components/theme/MainPaper';
import { usePurchaseOrdersQuery } from '../../queries/purchaseOrderQueries';
import { VendorParams } from '../../util/routes';

export const PurchaseOrderListPage = () => {
  const params = useParams<VendorParams>();

  const {
    isLoading,
    error,
    data: orders,
  } = usePurchaseOrdersQuery(params);

  const navigate = useNavigate();

  return (
    <MainPaper>
      <Section header={'Purchase Orders'}>
        <PurchaseOrderList
          isLoading={isLoading}
          error={error}
          purchaseOrders={orders}
          purchaseOrderSelected={(po) => navigate(`${po.purchase_order_id}`)}
        />
      </Section>
    </MainPaper>
  );
};
