// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#zipline-header-wrapper {
  box-shadow: none !important;
}
#zipline-header {
  padding-left: 0px;
  padding-right: 0px;
}
.header-spacer {
  color: #5a6069;
  padding: 0 12px;
}
.zip-logo {
  display: flex;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  border: none;
  background-color: inherit;
}
.zip-logo img {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;EACb,WAAW;EACX,qBAAqB;EACrB,sBAAsB;EACtB,0BAA0B;EAC1B,UAAU;EACV,SAAS;EACT,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB","sourcesContent":["#zipline-header-wrapper {\n  box-shadow: none !important;\n}\n#zipline-header {\n  padding-left: 0px;\n  padding-right: 0px;\n}\n.header-spacer {\n  color: #5a6069;\n  padding: 0 12px;\n}\n.zip-logo {\n  display: flex;\n  color: #fff;\n  text-decoration: none;\n  vertical-align: middle;\n  text-transform: capitalize;\n  padding: 0;\n  margin: 0;\n  border: none;\n  background-color: inherit;\n}\n.zip-logo img {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
