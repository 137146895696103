import React, { useMemo } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Document, DocumentType } from '../../../types';
import { StyledDataGrid } from '../../theme/StyledGrids';
import { ShortDateFormat } from '../../../util/grid';
import { DownloadDocumentAction } from '../download/DownloadDocumentAction';
import { ReplaceDocumentAction } from '../replace/ReplaceDocumentAction';
import { NoRowsOverlay } from '../../grid/NoRowsOverlay';

interface Props {
  isLoading: boolean;
  error?: any;
  documents: Document[] | undefined;
  documentType: DocumentType;
}

export const DocumentList = (props: Props) => {
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Filename',
        flex: 1,
      },
      {
        type: 'date',
        field: 'uploaded',
        headerName: 'Uploaded',
        flex: 0.5,
        valueFormatter: ShortDateFormat,
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 0.5,
      },
      {
        field: 'Actions',
        headerName: '',
        sortable: false,
        filterable: false,
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Document>) => {
          return (
            <>
              <ReplaceDocumentAction
                document={params.row}
                documentType={props.documentType}
              />
              <DownloadDocumentAction document={params.row} />
            </>
          );
        },
      },
    ],
    [props.documentType]
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          autoHeight
          disableRowSelectionOnClick
          loading={props.isLoading}
          rows={props.documents || []}
          getRowId={(row) => row.key}
          columns={cols}
          pageSizeOptions={[100]}
          hideFooterSelectedRowCount={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'uploaded',
                  sort: 'desc',
                },
              ],
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                error={props.error}
                noRowsMessage="No documents found"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};
