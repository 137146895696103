import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Part, PartsSearchParams, SubTask, Task } from '../types';
import {
  apiBodyAsJson,
  apiFetchEmptyWithError,
  apiFetchQueryWithError,
  apiFetchWithError,
} from '../util/api';
import {
  PartParams,
  SubtaskParams,
  TaskParams,
  VendorParams,
} from '../util/routes';

// API Hooks for each query

// Return parts only for a specific vendor
export const usePartsQuery = (params: Partial<VendorParams>) =>
  useQuery(['parts', params.vendorId], () =>
    apiFetchQueryWithError<Part[]>(`parts?vendor=${params.vendorId}`)
  );

// Return parts across all vendor, filtered by search params
// query is only enabled if there are filter params
export const useAllPartsQuery = (filterParams: PartsSearchParams) =>
  useQuery(
    ['parts', 'all', filterParams],
    () => {
      var urlParams = new URLSearchParams();

      // go through and add each filter param to the url
      let key: keyof PartsSearchParams;

      for (key in filterParams) {
        const value = filterParams[key];
        if (value) {
          urlParams.append(key, value);
        }
      }

      return apiFetchQueryWithError<Part[]>(`parts?${urlParams.toString()}`);
    },
    { enabled: Object.values(filterParams).some((v) => !!v) }
  );

export const usePartQuery = (params: Partial<PartParams>) =>
  useQuery(['part', params.vendorId, params.partId], () =>
    apiFetchQueryWithError<Part>(
      `vendors/${params.vendorId}/parts/${params.partId}`
    )
  );

export const useTaskQuery = (params: Partial<TaskParams>) =>
  useQuery(['part', params.vendorId, params.partId, params.taskId], () =>
    apiFetchQueryWithError<Task>(
      `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}`
    )
  );

export const useTaskStatusUpdate = (params: Partial<TaskParams>) => {
  const queryClient = useQueryClient();

  return useMutation(
    (status: string) =>
      apiFetchEmptyWithError(
        `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}`,
        {
          method: 'PATCH',
          body: apiBodyAsJson({
            status,
          }),
        }
      ),
    {
      onSuccess: (_, status) => {
        // on success, update the task status directly
        var queryKey = ['part', params.vendorId, params.partId, params.taskId];

        const queryTask: Task | undefined = queryClient.getQueryData(queryKey);

        if (queryTask) {
          queryClient.setQueryData(queryKey, { ...queryTask, status });
        }

        // also invalidate the part query that contains this task
        queryClient.invalidateQueries(['part', params.vendorId, params.partId]);
      },
    }
  );
};

export const useSubTaskQuery = (params: Partial<SubtaskParams>) =>
  useQuery(
    ['part', params.vendorId, params.partId, params.taskId, params.subTaskId],
    () =>
      apiFetchQueryWithError<SubTask>(
        `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}/subtasks/${params.subTaskId}`
      )
  );

export const useSubTaskStatusUpdate = (params: Partial<SubtaskParams>) => {
  const queryClient = useQueryClient();

  return useMutation(
    (status: string) =>
      apiFetchEmptyWithError(
        `vendors/${params.vendorId}/parts/${params.partId}/tasks/${params.taskId}/subtasks/${params.subTaskId}`,
        {
          method: 'PATCH',
          body: apiBodyAsJson({
            status,
          }),
        }
      ),
    {
      onSuccess: (_, status) => {
        var queryKey = [
          'part',
          params.vendorId,
          params.partId,
          params.taskId,
          params.subTaskId,
        ];

        var querySubtask: SubTask | undefined =
          queryClient.getQueryData(queryKey);

        if (querySubtask) {
          queryClient.setQueryData(queryKey, { ...querySubtask, status });
        }

        // also invalidate the task query that contains this subtask
        queryClient.invalidateQueries([
          'part',
          params.vendorId,
          params.partId,
          params.taskId,
        ]);
      },
    }
  );
};

// Delivery packages
export const useGenerateDeliveryPackage = (params: Partial<PartParams>) => {
  return useMutation(
    ({ part_rev, level }: { part_rev: string; level: 'single' | 'multi' }) =>
      apiFetchWithError<{ download_url: string }>(
        `vendors/${params.vendorId}/parts/${params.partId}/package`,
        { method: 'POST', body: apiBodyAsJson({ part_rev, level }) }
      )
  );
};
