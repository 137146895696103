import { AllParams } from '../util/routes';

// currently only for Parts and purchase orders
export const getQueryKey = (params: Partial<AllParams>) => {
  const key = [];

  if (params.partId) {
    key.push('part');
    if (params.vendorId) key.push(params.vendorId);
    if (params.partId) key.push(params.partId);
    if (params.taskId) key.push(params.taskId);
    if (params.subTaskId) key.push(params.subTaskId);
  } else if (params.purchaseOrderId) {
    key.push('purchase-orders');
    if (params.vendorId) key.push(params.vendorId);
    if (params.purchaseOrderId) key.push(params.purchaseOrderId);
  }

  return key;
};
