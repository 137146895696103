import React from 'react';

import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  defaultValue: Date;
  label: string;
  onChange: (date: Date | null) => void;
}

// standard date picker from material ui but with only a default value specified
export const UncontrolledDatePicker = (props: Props) => {
  const [localDate, setLocalDate] = React.useState<Date>(props.defaultValue);

  return (
    <DatePicker
      label={props.label}
      value={localDate}
      onChange={(newDate) => {
        // set local date state & call parent onChange
        setLocalDate(newDate || props.defaultValue);
        props.onChange(newDate);
      }}
      slotProps={{ textField: { variant: 'outlined' } }}
      // renderInput={(params) => <TextField {...params} />}
    />
  );
};
